import {ID} from '.';

export enum PolicyType {
  TOS = 'tos', // notion
  PRIVACY = 'privacy', // notion
  LOCATION = 'location', // notion
  FINANCIAL = 'financial', // notion
  INNOPAY = 'innopay',
  MEMBERSHIP = 'membership', // notion
  SETTLEMENT = 'settlement', // notion
  AI = 'ai', // notion
  PICKSCORE = 'pickscore',
}

export enum PolicyTypeNames {
  tos = '이용약관',
  privacy = '개인정보 처리방침',
  location = '위치기반 서비스 이용약관',
  financial = '전자금융거래 이용약관',
  innopay = '이노페이 서비스 이용약관',
  membership = '정기결제 약관',
  settlement = '정산 정책',
  ai = '볼닥앤플 AI 서비스 약관',
  pickscore = '픽스코어 개인정보 제3자 제공동의',
}

export interface IPolicy {
  id: ID;
  updatedAt: Date;
  type: PolicyType;
  text: string;

  enabled: boolean;
}
