import {Coordinate, ID, Photo, Regions} from '.';

export enum CenterLaneType {
  VIA = 'via',
  PROVIA = 'provia',
  nb = 'nb',
  PRONB = 'pronb',
  WOOD = 'wood',
  MERAY = 'meray',
  SWATCH = 'swatch',
  SPL = 'spl',
  ETC = 'etc',
  USER_INPUT = 'user-input',
}

export const CenterLaneTypeNames = {
  via: '비아',
  provia: '합성(비아)',
  nb: '엔비',
  pronb: '합성(프로엔비)',
  wood: '우드',
  meray: '머레이',
  swatch: '스와치',
  spl: 'SPL',
  etc: '합성(기타)',
  'user-input': '기타(직접입력)',
};
export enum CenterPinSetterType {
  AMF = 'amf',
  BRUNSWICK = 'brunswick',
  USER_INPUT = 'user-input',
}

export const CenterPinSetterTypeNames = {
  amf: 'AMF',
  brunswick: 'Brunswick',
  'user-input': '기타(직접입력)',
};

export enum CenterType {
  NORMAL = 'normal',
  ROCK = 'rock',
  NORMAL_ROCK = 'normal-rock',
  PROSHOP = 'proshop',
}

export const CenterTypeNames = {
  normal: '일반',
  rock: '락',
  'normal-rock': '일반/락',
  proshop: '프로샵',
};

export interface ICenterPricing {
  title: string;
  price: number;
}

export interface ICenter {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  enabled: boolean;

  deepLink?: string;

  name: string;
  address: string;
  coordinate: Coordinate;
  regions: Regions;
  addressDetail?: string;

  experiment?: boolean;

  phone?: string;

  cover?: Photo; // LIST_COVER

  author?: ID;

  //
  // 볼링장 추가하면서 추가된 필드들

  type?: CenterType;

  photos: Photo[];

  patternPhoto?: Photo; // 정비패턴사진
  patternName?: string; // 정비패턴명(ex: OOO볼링장 하우스 패턴)

  laneCount?: string; // 레인수
  laneType?: CenterLaneType; // 합성(비아), 합성(프로엔비), 우드, 직접입력
  laneTypeString?: string;

  pinSetterType?: CenterPinSetterType; // AMF , Brunswick , 기타
  pinSetterTypeString?: string;

  maintenanceTime?: string; // 정비시간 직접입력 (ex) OO시, OO시

  happyTimePricings: ICenterPricing[]; // 해피타임(요금을 할인하는 시간대 또는 몇게임에 얼마) ex)초중고(주말공유일) 3,330원
  freeGamePricings: ICenterPricing[]; // 프리게임( 게임수 관계없이 1시간,2시간에 얼마, 몇시까지 얼마) ex)(평일)3시간+볼링화(10~18) 10,000원

  proshopMajors?: string[];
  proshopText?: string;

  hasCapsuleEvent: boolean;
  hasNinepinEvent: boolean;
  hasCompetitionEvent: boolean;
  hasEventEvent: boolean;
  hasHappyTime: boolean;
  hasFreeGame: boolean;

  rating?: number;
  countRating: number;

  countCaller: number;

  countViewer: number;
  countBookmarker: number;

  countClub: number;

  openTime?: string; //영업 시간
  priceInfo?: string; // 이용요금
  shoePrice?: string; // 대화료

  storeCd?: string;

  usersEntities?: {
    id: ID;
    photo: Photo;
    name: string;
  }[];

  trainersEntities?: {
    id: ID;
    user: ID;
    enabled: boolean;
    experiment: boolean;
    photos: Photo[];
  }[];

  drillersEntities?: {
    id: ID;
    user: ID;
    enabled: boolean;
    experiment: boolean;
    photos: Photo[];
  }[];

  virtual?: {
    read: boolean;
    bookmark: boolean;
  };
}
