import {
  GoogleMap,
  MarkerClusterer,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import {FC} from 'react';
import {useRecordContext} from 'react-admin';

// https://github.com/JustFly1984/react-google-maps-api/issues/3030#issuecomment-1133835924
const GMapField: FC<any> = ({source}) => {
  const record = useRecordContext();

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APP_KEY as string,
  });

  // const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map: any) {
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map: any) {
  //   setMap(null);
  // }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{width: '100%', height: 400}}
      center={record[source]}
      zoom={16}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      <MarkerClusterer>
        {clusterer => (
          <>
            <MarkerF position={record[source]} clusterer={clusterer} />
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  ) : null;
};

export default GMapField;
