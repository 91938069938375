import {
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  required,
} from 'react-admin';

import {FC} from 'react';
import {IUser} from '../common/types';
import PickGameFrameInput from '../components/GameFrameInput';

const PickGameFilter = [
  <ReferenceInput source="user" reference="users" fullWidth alwaysOn>
    <AutocompleteInput
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="회원"
    />
  </ReferenceInput>,
  <ReferenceInput
    source="pickCenter"
    reference="pick-centers"
    fullWidth
    alwaysOn>
    <AutocompleteInput optionText="name" label="센터" />
  </ReferenceInput>,
];
export const PickGameList = () => (
  <List
    filters={PickGameFilter}
    sort={{field: 'gameStartAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField label="회원" reference="users" source="user">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="센터" reference="pick-centers" source="pickCenter">
        <TextField source="name" />
      </ReferenceField>

      <DateField label="게임시작일시" source="gameStartAt" showTime={true} />
      <DateField label="게임종료일시" source="gameEndAt" showTime={true} />
      <NumberField label="점수" source="totalScore" />
    </Datagrid>
  </List>
);

const PickGameTitle = () => {
  return <span>픽스코어 게임</span>;
};

export const PickGameEdit = () => {
  return (
    <Edit title={<PickGameTitle />}>
      <SimpleForm>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="회원" reference="users" source="user">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="센터"
            reference="pick-centers"
            source="pickCenter">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField
            label="게임시작일시"
            source="gameStartAt"
            showTime={true}
          />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField label="게임종료일시" source="gameEndAt" showTime={true} />
        </Labeled>
        <NumberInput label="점수" source="totalScore" validate={[required()]} />
        <PickGameFrameInput source="frames" />
      </SimpleForm>
    </Edit>
  );
};

//
// Create
export const PickGameCreate: FC<CreateProps> = props => {
  const optionRenderer = (choice: IUser) => `${choice.name}(${choice.phone})`;

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="회원"
          source="user"
          reference="users"
          validate={[required()]}>
          <AutocompleteInput optionText={optionRenderer} sx={{width: '65vw'}} />
        </ReferenceInput>

        <ReferenceInput
          source="pickCenter"
          reference="pick-centers"
          fullWidth
          validate={[required()]}>
          <AutocompleteInput optionText="name" label="센터" />
        </ReferenceInput>

        <DateTimeInput
          label="게임시작일시"
          source="gameStartAt"
          validate={[required()]}
        />
        <DateTimeInput
          label="게임종료일시"
          source="gameEndAt"
          validate={[required()]}
        />

        <NumberInput label="점수" source="totalScore" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
