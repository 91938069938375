import {Box, Button, Card, CircularProgress, TextField} from '@mui/material';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {Notification, useLogin, useNotify} from 'react-admin';
import {Controller, useForm} from 'react-hook-form';
import useApp from '../hooks/useApp';

type FormData = {
  username: string;
  password: string;
};

const LoginPage = ({theme}: any) => {
  const notify = useNotify();
  const login = useLogin();
  const [loading, setLoading] = useState(false);
  const {apk} = useApp();

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    login(data)
      .catch(err => {
        notify(err.message, {type: 'error'});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={styles.main}>
      <Card sx={styles.card}>
        <Box sx={styles.hint}>볼닥 관리자</Box>
        <Box sx={styles.hint}>관리자 ID(이메일)를 입력해 주세요.</Box>

        <Box sx={styles.form}>
          <Controller
            control={control}
            name="username"
            defaultValue=""
            rules={{
              required: '필수 항목입니다.',
            }}
            render={({field: {onChange, onBlur, value}}) => (
              <TextField
                type="email"
                autoFocus
                sx={styles.input}
                placeholder="아이디"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                error={!!errors.username?.message}
                helperText={errors.username?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: '필수 항목입니다.',
            }}
            render={({field: {onChange, onBlur, value}}) => (
              <TextField
                type="password"
                sx={styles.input}
                placeholder="비밀번호"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                error={!!errors.password?.message}
                helperText={errors.password?.message}
              />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            sx={styles.button}
            onClick={handleSubmit(onSubmit)}>
            {loading && <CircularProgress size={18} thickness={2} />}
            로그인
          </Button>
        </Box>
      </Card>

      <Card sx={styles.card}>
        <Box sx={styles.cardApk}>
          <a href={apk?.url}>테스트 버전 다운로드 {apk?.key}</a>
          <br />
          <span>
            last updated at:{' '}
            {dayjs(apk?.lastModified).format('YYYY-MM-DD HH:mm')}
          </span>
        </Box>
      </Card>

      <Card sx={styles.card}>
        상호: 볼리즘
        <br />
        대표자: 백두현
        <br />
        사업자번호: 222-56-00233
        <br />
        주소 : 경기도 남양주시 경춘로 1256번길 9, 3층 305호
        <br />
        대표번호 : 070-8095-9989
        <br />
      </Card>

      <Notification />
    </Box>
  );
};

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
      'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  cardApk: {
    marginBottom: '1em',
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: '#aaaaaa',
  },
  form: {
    padding: '1em 1em 1em 1em',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '1em',
  },
  button: {
    marginBottom: '1em',
    width: '100%',
  },
};

export default LoginPage;
