import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

//
// List
export const VirtualNumberList = () => (
  <List sort={{field: 'virtualNumber', order: 'ASC'}} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField label="가상번호" source="virtualNumber" />
      <TextField label="실제번호" source="realNumber" />
      <ReferenceField label="회원" source="user" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="매칭일" source="matchedAt" showTime={true} />
    </Datagrid>
  </List>
);
