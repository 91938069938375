import {stringify} from 'query-string';
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import {Api} from './api';
import {
  ImageType,
  uploadFaceswapImageAsync,
  uploadFaceswapVideoAsync,
  uploadImageAsync,
} from './imageUpload';
import {getFileName} from './stringUtils';

const getList = async (resource: string, params: GetListParams) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  const from = (page - 1) * perPage;
  const to = page * perPage - 1;
  const query = {
    offset: from,
    limit: to - from + 1,
    sort: (order === 'DESC' ? '-' : '') + field,
    filter: JSON.stringify(params.filter),
  };

  return Api.get<any, any>(`/${resource}?${stringify(query)}`);
};

const getOne = async (resource: string, params: GetOneParams) => {
  const data = await Api.get<any, any>(`/${resource}/${params.id}`);
  return {data};
};

const getMany = async (resource: string, params: GetManyParams) =>
  Api.get<any, any>(
    `/${resource}?${stringify({
      filter: JSON.stringify({id: params.ids}),
    })}`,
  );

const getManyReference = async (
  resource: string,
  params: GetManyReferenceParams,
) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  const from = (page - 1) * perPage;
  const to = page * perPage - 1;
  const query = {
    offset: from,
    limit: to - from + 1,
    sort: (order === 'DESC' ? '-' : '') + field,
    filter: JSON.stringify(params.filter),
    ref: true,
  };

  return Api.get<any, any>(`/${resource}?${stringify(query)}`);
};

const update = async (resource: string, params: UpdateParams) => {
  switch (resource) {
    case 'banners':
      params.data = await imageUpload(params.data, 'photo', 'BANNER');
      break;
    case 'popup-notices':
      params.data = await imageUpload(params.data, 'photo', 'POPUP');
      break;
    case 'users':
      params.data = await imageUpload(params.data, 'photo', 'PROFILE');
      params.data = await imagesUpload(params.data, 'bankPhotos', 'PHOTO');
      break;
    case 'trainers':
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      params.data = await imagesUpload(params.data, 'majorPhotos', 'PHOTO');
      params.data = await imagesUpload(params.data, 'certifiedPhotos', 'PHOTO');
      break;
    case 'drillers':
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      break;
    case 'lessons':
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      break;
    case 'products':
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      break;
    case 'reviews':
      params.data = await imageUpload(params.data, 'receipt', 'PHOTO');
      break;
    case 'centers':
      params.data = await imageUpload(params.data, 'cover', 'PROFILE');
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      params.data = await imageUpload(params.data, 'patternPhoto', 'PHOTO');
      break;
    case 'center-events':
      params.data = await imageUpload(params.data, 'cover', 'PROFILE');
      params.data = await imagesUpload(params.data, 'contentPhotos', 'PHOTO');
      break;
    case 'center-clubs':
      params.data = await imageUpload(params.data, 'cover', 'PROFILE');
      params.data = await imagesUpload(params.data, 'contentPhotos', 'PHOTO');
      break;
    case 'movies':
      params.data = await faceswapImageUpload(params.data, 'sourceImage');
      params.data = await faceswapImageUpload(params.data, 'sourceImage2');
      params.data = await faceswapVideoUpload(params.data, 'sourceVideo');
      break;
    case 'galleries':
      params.data = await faceswapImageUpload(params.data, 'photo');

      break;
    case 'mall-products':
      params.data = await imageUpload(params.data, 'cover', 'PROFILE');
      params.data = await imagesUpload(params.data, 'photos', 'PHOTO');
      break;

    default:
      break;
  }

  const data = await Api.put<any, any>(`${resource}/${params.id}`, params.data);

  return {data};
};

const create = async (resource: string, params: CreateParams) => {
  switch (resource) {
    case 'movies':
      params.data = await faceswapImageUpload(params.data, 'sourceImage');
      params.data = await faceswapImageUpload(params.data, 'sourceImage2');
      params.data = await faceswapVideoUpload(params.data, 'sourceVideo');
      break;
    default:
      break;
  }

  const data = await Api.post<any, any>(`${resource}`, params.data);

  return {data};
};

const updateMany = (resource: string, params: UpdateManyParams) =>
  Api.put<any, any>(
    `${resource}?${stringify({
      filter: JSON.stringify({id: params.ids}),
    })}`,
    params.data,
  );

// const create = (resource: string, params: CreateParams) =>
//   Api.post<any, any>(`${resource}`, params.data).then(data => ({data}));

const deleteFn = (resource: string, params: DeleteParams) =>
  Api.delete<any, any>(`${resource}/${params.id}`).then(data => ({data}));

const deleteMany = (resource: string, params: DeleteManyParams) =>
  Api.delete<any, any>(resource, {
    data: {
      ids: params.ids,
    },
  }).then(data => ({data}));

const _isRawFile = (p: any): boolean => {
  return p.rawFile instanceof File || p.rawFile instanceof Blob;
};

const imagesUpload = async (data: any, path: string, type: ImageType) => {
  const newPictures = data[path].filter((p: any) => _isRawFile(p));

  const formerPictures = data[path]
    .filter((p: any) => !_isRawFile(p))
    .map((picture: string) => getFileName(picture));

  const keys = await Promise.all(
    newPictures.map((file: any) => uploadImageAsync(file.rawFile as any, type)),
  );

  data[path] = [...formerPictures, ...keys];
  return data;
};

const imageUpload = async (data: any, path: string, type: ImageType) => {
  if (!data[path]) return data;

  const isNewPhoto = data[path].rawFile instanceof File;

  if (isNewPhoto) {
    data[path] = await uploadImageAsync(data[path].rawFile as any, type);
  } else {
    data[path] = getFileName(data[path]);
  }

  return data;
};

const faceswapImageUpload = async (data: any, path: string) => {
  if (!data[path]) return data;

  const isNewPhoto = data[path].rawFile instanceof File;

  if (isNewPhoto) {
    data[path] = await uploadFaceswapImageAsync(data[path].rawFile);
  } else {
    data[path] = getFileName(data[path]);
  }

  return data;
};

const faceswapVideoUpload = async (data: any, path: string) => {
  if (!data[path]) return data;

  const isNewPhoto = data[path].rawFile instanceof File;

  if (isNewPhoto) {
    data[path] = await uploadFaceswapVideoAsync(data[path].rawFile);
  } else {
    data[path] = getFileName(data[path]);
  }

  return data;
};

const functions = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  delete: deleteFn,
  deleteMany,
};

export default functions;
