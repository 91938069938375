import {useState} from 'react';
import {
  Confirm,
  SaveButton,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {useFormContext} from 'react-hook-form';
import {IMallOrder, MallOrderState} from '../../common/types';
import {Api} from '../../utils/api';

const MallOrderCancelStateButton = () => {
  const mallOrder = useRecordContext<IMallOrder>();
  const {getValues} = useFormContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const doCommand = async () => {
    try {
      const {id} = getValues();
      setIsLoading(true);

      await Api.put(`/mall-orders/${id}/canceled`);
      refresh();
    } catch (err: any) {
      notify(err.message, {type: 'warning'});
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  let allowCommand = false;
  const commandTitle = '취소';

  switch (mallOrder.state) {
    case MallOrderState.PAID:
      allowCommand = true;
      break;
    case MallOrderState.PACKING:
      allowCommand = true;
      break;
    case MallOrderState.PREPARING:
      allowCommand = true;
      break;
    case MallOrderState.SHIPPING:
      break;
    case MallOrderState.ARRIVED:
      break;
    case MallOrderState.CLOSED:
      break;
    case MallOrderState.CANCELED:
      break;
  }

  if (!allowCommand) {
    return null;
  }

  return (
    <>
      <SaveButton
        color="warning"
        alwaysEnable
        label={commandTitle}
        type="button"
        sx={{marginX: 1}}
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      />

      <Confirm
        isOpen={open}
        loading={isLoading}
        title="주문 상태변경"
        content={`${commandTitle}?`}
        onConfirm={doCommand}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default MallOrderCancelStateButton;
