import * as React from 'react';
import {FC} from 'react';
import {ImageField, useRecordContext} from 'react-admin';

const PreviewImage: FC<any> = ({source}) => {
  let record: any = useRecordContext();

  if (typeof record == 'string') {
    // http url
    record = {
      [source]: record,
    };
  } else {
    // local url blob:
    record = {
      [source]: record[source],
    };
  }
  return (
    <a href={record[source]} target="_blank" rel="noreferrer">
      <ImageField
        record={record}
        source={source}
        sx={{
          '& .RaImageField-image': {
            height: 100,
            width: 100,
            objectFit: 'cover',
            marginRight: 1,
          },
        }}
      />
    </a>
  );
};

export default PreviewImage;
