import * as React from 'react';
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TopToolbar,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  CertificateCancelType,
  CertificateCancelTypeNames,
  CertificateState,
  CertificateStateNames,
  CertificateType,
  CertificateTypeNames,
  ICertificate,
  ID,
  IUser,
  PaymentMethodName,
  PaymentSettlementState,
  PaymentSettlementStateNames,
  RoleType,
} from '../common/types';

import dayjs from 'dayjs';

import {Card, CardContent, Grid, Typography} from '@mui/material';

import ListHelper from '../components/ListHelper';
// import Thumbnail from "../components/Thumbnail";

import {CircularProgress} from '@mui/material';

import IconExport from '@mui/icons-material/GetApp';
import {Api, ApiError} from '../utils/api';
import SettlementIcon from '@mui/icons-material/MonetizationOn';
import SettlementReadyIcon from '@mui/icons-material/PriceCheck';
import {fileDownload} from '../utils/fileDownload';

const SettlementReadyButton = () => {
  const {selectedIds} = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  return (
    <Button
      label="정산예정"
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);

          const res = (await Api.put(`/payments`, {
            ids: selectedIds,
            state: PaymentSettlementState.READY,
          })) as ID[];

          refresh();
          notify(`${res.length}개 변경하였습니다.`);
        } catch (err) {
          const e = err as ApiError;
          notify(e.message, {type: 'warning'});
        } finally {
          setLoading(false);
        }
      }}>
      <SettlementReadyIcon />
    </Button>
  );
};

const SettlementButton = () => {
  const {selectedIds} = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  return (
    <Button
      label="정산완료"
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);

          const res = (await Api.put(`/payments`, {
            ids: selectedIds,
            state: PaymentSettlementState.COMPLETED,
          })) as ID[];

          refresh();
          notify(`${res.length}개 변경하였습니다.`);
        } catch (err) {
          const e = err as ApiError;
          notify(e.message, {type: 'warning'});
        } finally {
          setLoading(false);
        }
      }}>
      <SettlementIcon />
    </Button>
  );
};

const BulkActionButtons = () => {
  const {selectedIds} = useListContext();
  const {permissions} = usePermissions();
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <SettlementReadyButton />
      <SettlementButton />
      {permissions === RoleType.ADMIN && (
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              const blob = await Api.get(
                `/certificates?downloadSelected=${encodeURIComponent(
                  JSON.stringify({
                    ids: selectedIds,
                  }),
                )}&offset=0&limit=0`,
                {
                  responseType: 'blob',
                },
              );
              const filename = `수강.xlsx`;
              fileDownload(blob, filename);
            } catch (err) {
              // notify(err, "warning");
            } finally {
              setLoading(false);
            }
          }}
          label="엑셀">
          {loading ? (
            <CircularProgress size={18} thickness={2} />
          ) : (
            <IconExport />
          )}
        </Button>
      )}
    </>
  );
};

const CertificateFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(CertificateType).map(value => ({
      id: value,
      name: CertificateTypeNames[value],
    }))}
    alwaysOn
  />,

  <SelectInput
    label="수강상태"
    source="state"
    choices={Object.values(CertificateState).map(value => ({
      id: value,
      name: CertificateStateNames[value],
    }))}
    alwaysOn
  />,

  <ReferenceInput
    source="trainer"
    reference="trainers"
    fullWidth
    alwaysOn
    filter={{enabled: true}}>
    <AutocompleteInput optionText="name" label="트레이너" />
  </ReferenceInput>,
  <ReferenceInput
    source="user"
    reference="users"
    filter={{role: RoleType.USER}}
    fullWidth
    alwaysOn>
    <AutocompleteInput
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="일반회원"
    />
  </ReferenceInput>,

  <DateInput label="결제일(시작일)" source="createdFrom" alwaysOn />,
  <DateInput label="결제일(종료일)" source="createdTo" alwaysOn />,
];

const ListActions = () => {
  const {permissions} = usePermissions();
  const [loading, setLoading] = React.useState(false);
  const {filterValues, sort} = useListContext();

  const realsort = (sort.order === 'DESC' ? '-' : '') + sort.field;

  return (
    <TopToolbar>
      <FilterButton />
      {permissions === RoleType.ADMIN && (
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              const blob = await Api.get(
                `/certificates?download=true&offset=0&limit=0&filter=${encodeURIComponent(
                  JSON.stringify({
                    ...filterValues,
                  }),
                )}&sort=${realsort}`,
                {
                  responseType: 'blob',
                },
              );
              const filename = `수강.xlsx`;
              fileDownload(blob, filename);
            } catch (err) {
              // notify(err, "warning");
            } finally {
              setLoading(false);
            }
          }}
          label="엑셀">
          {loading ? (
            <CircularProgress size={18} thickness={2} />
          ) : (
            <IconExport />
          )}
        </Button>
      )}
    </TopToolbar>
  );
};

export const CertificateList = () => {
  const {permissions} = usePermissions();
  return (
    <>
      <ListHelper
        contents={[
          {
            title: '정산예정',
            text: `수강종료/수강취소 상태에서 정산예정으로 변경 가능합니다.`,
          },
          {
            title: '정산완료',
            text: `정산예정 상태에서 변경 가능합니다.`,
          },
          {
            title: '수강상태',
            text: `신청: 회원이 수업결제
확정: 강사가 수업날짜 등록
종료: 강사가 출석/노쇼 설정하고 수업 종료
취소: 취소됨`,
          },
          {
            title: '취소타입',
            text: `회원취소: 회원이 취소
트레이너취소: 강사가 취소
미확정자동취소: 신청후 48시간 내에 확정이 안된 경우 자동 취소`,
          },
          {
            title: '회원취소시 환불',
            text: `확정일 당일취소: 환불없음
확정일 1일전취소: 50% 환불(맛보기만 해당)
확정일 2일전취소: 30% 환불(맛보기만 해당)`,
          },
        ]}
      />

      <List
        actions={<ListActions />}
        filters={CertificateFilter}
        sort={{field: 'createdAt', order: 'DESC'}}
        exporter={false}>
        <Datagrid
          rowClick="expand"
          expand={<InvoiceShow />}
          bulkActionButtons={
            permissions === RoleType.ADMIN ? <BulkActionButtons /> : false
          }>
          <SelectField
            label="타입"
            source="type"
            choices={Object.values(CertificateType).map(value => ({
              id: value,
              name: CertificateTypeNames[value],
            }))}
          />

          <ReferenceField
            label="트레이너"
            source="trainer"
            reference="trainers">
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField label="회원" source="user" reference="users">
            <TextField source="name" />
          </ReferenceField>

          <NumberField label="결제금액" source="paymentEntity.amount" />

          <NumberField label="환불금액" source="paymentEntity.refundAmount" />

          <NumberField
            label="정산예상금액"
            source="paymentEntity.settlementExpectAmount"
          />
          <NumberField
            label="정산금액"
            source="paymentEntity.settlementAmount"
          />

          <SelectField
            label="수강상태"
            source="state"
            choices={Object.values(CertificateState).map(value => ({
              id: value,
              name: CertificateStateNames[value],
            }))}
          />

          <SelectField
            label="취소타입"
            source="cancelType"
            choices={Object.values(CertificateCancelType).map(value => ({
              id: value,
              name: CertificateCancelTypeNames[value],
            }))}
          />

          <DateField label="결제일" source="createdAt" showTime={true} />
          <DateField label="수업일" source="classDate" showTime={true} />
          <DateField label="종료일" source="closedDate" showTime={true} />

          <SelectField
            label="정산상태"
            source="paymentEntity.settlementState"
            choices={Object.values(PaymentSettlementState).map(value => ({
              id: value,
              name: PaymentSettlementStateNames[value],
            }))}
          />

          <ReferenceField label="리뷰" source="review" reference="reviews">
            <DateField source="createdAt" />
          </ReferenceField>
        </Datagrid>
      </List>
    </>
  );
};

const InvoiceShow = () => {
  const record = useRecordContext<ICertificate>();
  if (!record) return null;
  return (
    <Card sx={{width: 600, margin: 'auto'}}>
      <CardContent>
        <Grid container>
          <Typography>결제수단</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.method
              ? PaymentMethodName[record.paymentEntity?.method]
              : ''}
          </Typography>
        </Grid>

        <Grid container>
          <Typography>TID</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.tid}
          </Typography>
        </Grid>

        <Grid container>
          <Typography>주문번호</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.number}
          </Typography>
        </Grid>

        {record.paymentEntity?.discountAmount !== undefined && (
          <Grid container>
            <Typography>할인금액</Typography>
            <Typography sx={{marginLeft: 8}}>
              {record.paymentEntity?.discountAmount}
            </Typography>
          </Grid>
        )}

        {record.headCount === undefined && record.totalRound !== undefined && (
          <Grid container>
            <Typography>수업명</Typography>
            <Typography sx={{marginLeft: 8}}>
              일반레슨 {record.totalRound}회
            </Typography>
          </Grid>
        )}

        {record.headCount !== undefined && (
          <Grid container>
            <Typography>수업명</Typography>
            <Typography sx={{marginLeft: 8}}>
              그룹레슨 {record.headCount}인 {record.totalRound}회
            </Typography>
          </Grid>
        )}

        {record.optionText && (
          <Grid container>
            <Typography>추가옵션</Typography>
            <Typography sx={{marginLeft: 8}}>{record.optionText}</Typography>
          </Grid>
        )}

        {record.confirmedAt && (
          <Grid container>
            <Typography>확정된 일시</Typography>
            <Typography sx={{marginLeft: 8}}>
              {dayjs(record.confirmedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </Grid>
        )}
        {record.closedAt && (
          <Grid container>
            <Typography gutterBottom>종료된 일시 </Typography>
            <Typography sx={{marginLeft: 8}}>
              {dayjs(record.closedAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </Grid>
        )}
        {record.canceledAt && (
          <Grid container>
            <Typography gutterBottom>취소된 일시 </Typography>
            <Typography sx={{marginLeft: 8}}>
              {dayjs(record.canceledAt).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </Grid>
        )}
        {record.cancelText && (
          <Grid container>
            <Typography gutterBottom>취소 상세</Typography>
            <Typography sx={{marginLeft: 8}}>{record.cancelText}</Typography>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
