import {ID} from '.';

export enum CategoryType {
  PITCH = 'pitch',
  PRODUCT = 'product',
  FAQ = 'faq',
  DRILL = 'drill',
  MALL_PRODUCT_BRAND = 'mall-product-brand',
  MALL_PRODUCT_TYPE = 'mall-product-type',
}

export const CategoryTypeNames = {
  pitch: '투구스타일',
  product: '상품',
  faq: 'FAQ',
  drill: '지공',
  'mall-product-brand': '쇼핑몰 상품 브랜드',
  'mall-product-type': '쇼핑몰 상품 타입',
};

export interface ICategory {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  type: CategoryType;
  name: string;

  enabled: boolean;

  count: number; // 트레이너

  position: number;

  tag?: string;
}
