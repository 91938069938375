import {ID, Photo, Video, VideoExInfo} from '.';

export enum MovieState {
  IDLE = 'idle',
  REQUEST = 'request',
  GENERATING = 'generating',
  SUCCESS = 'success',
  FAILED = 'failed',
}
export const MovieStateNames = {
  idle: '대기',
  request: '요청',
  generating: '생성중',
  success: '성공',
  failed: '실패',
};

export enum MovieType {
  VIDEO = 'video',
  PHOTO = 'photo',
}

export const MovieTypeNames = {
  video: '비디오',
  photo: '사진',
};

export interface MovieCheckResult {
  movieId: ID; // movieId
  price: number; // 표시되는 가격
  amount: number; // 실제 결제되어야 하는 가격
  discountAmount: number; // 할인 금액
  title: string;
  payParams: {
    MID?: string;
    Moid?: string;
    MallUserID: string; // innopay에만 필요함
    MerchantKey: string; // innopay에만 필요함
    OfferingPeriod: string; // innopay에만 필요함
    kcpMoid: string;
    kcpUserCode: string;
    kcpId: string;
  };
}

export interface IMovie {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  state: MovieState;

  type: MovieType;
  gallery?: ID;

  error?: string;

  requestAt?: Date;
  generatingStartAt?: Date;
  generatingEndAt?: Date;

  user: ID;
  enabled: boolean;

  confirmed: boolean; // 사용자에 의해 확인됨

  videoInfo?: VideoExInfo; // 삭제예정

  sourceImage: Photo;
  sourceVideo?: Video;
  sourceImage2?: Photo;

  resultMedia?: Video | Photo;
  watermarkedMedia?: Video | Photo;
  resultThumb?: Photo;
  resultDuration?: number; // 초단위
  resultSize?: number; // bytes
  resultResolutionX?: number;
  resultResolutionY?: number;
  resultMediaType?: string;

  // 워터마크가 없는 원본 미디어
  // 결제하거나 추천받은 미디어로 합성한 영상은 true
  pureMedia: boolean;

  payment?: ID;
}
