import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  Labeled,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {IPickCenter, RoleType} from '../common/types';

const PickCenterFilter = [<TextInput label="이름 지역" source="q" alwaysOn />];

export const PickCenterList = () => (
  <List
    filters={PickCenterFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="이름" source="name" />
      <TextField label="가맹점코드" source="storeCd" />
      <TextField label="주소" source="address" />{' '}
      <BooleanField label="활성" source="enabled" />
    </Datagrid>
  </List>
);

const PickCenterTitle = () => {
  const record = useRecordContext<IPickCenter>();
  return <span>볼링픽 제휴 볼링장 {record ? `"${record.name}"` : ''}</span>;
};

const PickCenterEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
        </>
      )}
    </Toolbar>
  );
};

export const PickCenterEdit = () => {
  return (
    <>
      <Edit title={<PickCenterTitle />}>
        <SimpleForm toolbar={<PickCenterEditToolbar />}>
          <Labeled sx={{marginBottom: 4}}>
            <TextField label="가맹점코드" source="storeCd" fullWidth />
          </Labeled>
          <Labeled sx={{marginBottom: 4}}>
            <TextField label="가맹점명" source="name" fullWidth />
          </Labeled>
          <Labeled sx={{marginBottom: 4}}>
            <TextField label="가맹점주소" source="address" fullWidth />
          </Labeled>
          <BooleanInput label="활성" source="enabled" />
        </SimpleForm>
      </Edit>
    </>
  );
};
