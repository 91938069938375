import {Editor} from '@tinymce/tinymce-react';
import axios from 'axios';
import React, {FC, useCallback} from 'react';
import {useInput} from 'react-admin';
import {TINYMCE_KEY} from '../constants';
import {Api} from '../utils/api';
import useApp from '../hooks/useApp';
/**
 * https://marmelab.com/react-admin/Inputs.html#recipes
 *
 */
const RTEditor: FC<any> = props => {
  const {s3Url} = useApp();

  const {
    field: {onChange, value},
  } = useInput(props);

  const handleChange = (content: string) => {
    onChange(content);
  };

  const imagesUploadHandler = useCallback(
    async (blobInfo: any, success: any, failure: any, progress: any) => {
      try {
        if (!s3Url) throw new Error('시스템정보가 없습니다.');
        const response = (await Api.post('/signed-url', {
          type: 'RTEDITOR',
        })) as any;

        await axios.put(response[0].url, blobInfo.blob(), {
          headers: {
            'Content-Type': 'image/jpeg',
          },
        });

        const key = response[0].key;
        success(`${s3Url}/${key}`);
      } catch (err) {
        failure(err);
      }
    },
    [s3Url],
  );

  return (
    <Editor
      apiKey={TINYMCE_KEY}
      init={{
        width: '100%',
        // height: 800,
        // max_height: 800,
        extended_valid_elements: 'style,link[href|rel]',
        custom_elements: 'style,link,~link',
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        // themes: config.themes,
        // inline: true,
        plugins: [
          'autolink lists link image imagetools charmap preview anchor',
          'searchreplace visualblocks code fullscreen',
          'media table paste code textcolor directionality autoresize',
          'emoticons',
          'charmap',
        ],
        toolbar1:
          'image media | styleselect | fontsizeselect | fontselect | bold italic underline strikethrough bullist numlist link alignleft aligncenter alignright alignjustify',
        toolbar2:
          'undo redo | forecolor backcolor paste removeformat table | outdent indent | emoticons charmap | preview code',
        menubar: false,
        toolbar_sticky: true,
        // fontsize_formats: "9px 10px 12px 14px 18px 23px 32px 36px",
        font_formats: 'Noto Sans=Noto Sans KR',
        style_formats_merge: true,
        automatic_uploads: true,
        // URL of our upload handler (for more details check: https://www.tinymce.com/docs/configure/file-image-upload/#images_upload_url)
        // here we add custom filepicker only to Image dialog
        file_picker_types: 'image',
        // images_upload_url: uploadUrl,
        image_caption: true,
        images_upload_handler: imagesUploadHandler,
      }}
      value={value} //https://stackoverflow.com/questions/68573724/tinymce-react-cursor-back-to-start-after-typing-a-key
      onEditorChange={handleChange}
    />
  );
};

export default RTEditor;
