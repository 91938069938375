import {ID, Photo, RoleType, VideoInfo} from '.';

export enum AuthProvider {
  KAKAO = 'kakao',
  NAVER = 'naver',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export const AuthProviderNames = {
  kakao: '카카오',
  naver: '네이버',
  email: '이메일',
  facebook: '페이스북',
  google: '구글',
  apple: 'Apple',
};

export enum UserState {
  JOINED = 'joined',
  LEFT = 'left',
}

export const UserStateNames = {
  joined: '가입',
  left: '탈퇴',
};

export enum GenderType {
  MALE = 'male',
  FEMALE = 'female',
}

export const GenderTypeNames = {
  male: '남성',
  female: '여성',
};

export enum HandType {
  LEFTY = 'lefty',
  RIGHTY = 'righty',
  // AMBIDEXTROUS = 'ambidextrous'
}

export const HandTypeNames = {
  lefty: '왼손',
  righty: '오른손',
};
export interface IUserSocialInfo {
  id: string;
  nickname?: string;
  profileImage?: string;
  mobile?: string;
  email?: string;
  name?: string;
}

export interface IUserPickRecord {
  averageScore: number;
  totalGameCount: number;
  maxScore: number;
  minScore: number;

  monthsCount: number[]; // 12개
  frameStrikeCounts: number[]; //10개
  frameSpareCounts: number[]; //10개
  frameSplitCounts: number[]; //10개
  framePorketRightCounts: number[]; //10개
  framePorketLeftCounts: number[]; //10개
}

export interface IUserPickCenterInfo {
  createdAt: Date;
  pickCenter: ID;
  memberNo: string;
  gameCount: number;
}

export interface IUser {
  id: ID;

  createdAt: Date;
  updatedAt: Date;
  accessedAt: Date;

  leftAt?: Date;

  state: UserState;
  role: RoleType;

  activeDriller: boolean;
  activeTrainer: boolean;
  attention: boolean;
  memo?: string;
  registrationNumber?: string;

  username: string;
  password: string;

  /*
BA-일반회원 / BP-파트너
23-가입연도
1210-월일
00001-넘버링
*/
  subUsername: string;

  authProvider: AuthProvider;

  socialInfo?: IUserSocialInfo;

  experiment: boolean;
  enableMarket: boolean;

  email?: string;

  //
  // 실명인증
  name?: string;
  phone?: string;
  birthday?: string;
  gender?: GenderType;

  photo?: Photo;
  signature?: Photo;

  virtualPhone?: string;

  unreadChatCount: number;
  unreadNotificationCount: number;

  leftReason?: string;

  career?: number;
  average?: number;
  pitchStyle?: ID;
  hand?: HandType;

  driller?: ID;
  trainer?: ID;

  bankUpdatedAt?: Date;
  bankAccountName?: string;
  bankCode?: string;
  bankAccountNumber?: string;
  bankPhotos: Photo[];

  enableNotiTalk: boolean;
  enableDisturb: boolean;
  disturbRange: number[];

  contactMemo?: string;

  averageResponseMinutes?: number;

  paidTrainers: ID[];
  closedTrainers: ID[];
  reviewTrainers: ID[];

  appVersion?: string;

  videoInfos?: VideoInfo[];

  certificateTrainerProgressCount: number;
  certificateDrillerProgressCount: number;
  certificateSingleProgressCount: number;
  certificateGroupProgressCount: number;

  orderProgressCount: number;

  pickScoreMember: boolean;
  pickCenterInfos: IUserPickCenterInfo[];
  pickRecord: IUserPickRecord;

  noticesLastViewedAt?: Date;
}
