import jwtDecode from 'jwt-decode';
import {IUser, RoleType} from '../common/types';
import {Api} from './api';

const parseToken = (token: string) => {
  const decoded: any = jwtDecode(token);

  return {
    id: decoded.id,
    role: decoded.role,
    username: decoded.username,
  };
};

// called when the user attempts to log in
const login = async ({username, password}: any): Promise<any> => {
  // accept all username/password combinations
  const {accessToken, refreshToken} = await Api.post<any, any>('/auth/login', {
    username,
    password,
  });

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

// called when the user clicks on the logout button
const logout = async () => {
  try {
    await Api.post('/auth/logout', {
      refreshToken: localStorage.getItem('refreshToken'),
    });
  } catch (err) {}
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

// called when the API returns an error
const checkError = ({status}: any) => {
  if (status === 401) {
    localStorage.removeItem('accessToken');
    return Promise.reject();
  } else if (status === 403) {
    // forbiden 일 경우 home으로 이동
    window.location.replace('/');
    return Promise.resolve();
  }
  return Promise.resolve();
};

// https://github.com/marmelab/react-admin/issues/5229#issuecomment-773175902
// called when the user navigates to a new location, to check for authentication
const checkAuth = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return Promise.reject();

  const data = parseToken(token);
  if (data.role !== RoleType.ADMIN && data.role !== RoleType.ADMIN_OBSERVER)
    return Promise.reject('권한이 없습니다.');

  return Promise.resolve();
};

// called when the user navigates to a new location, to check for permissions / roles
const getPermissions = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return Promise.reject();

  const data = parseToken(token);
  return Promise.resolve(data.role);
};

const getIdentity = async () => {
  try {
    const user = (await Api.get('/users/me')) as IUser;
    let prefix = '';

    return Promise.resolve({
      id: user.id,
      fullName: prefix + user.name + '님',
      avatar: user.photo,
    });
  } catch (err) {
    return Promise.reject();
  }
};

const functions = {
  login,
  logout,
  checkError,
  checkAuth,
  getPermissions,
  getIdentity,
};

export default functions;
