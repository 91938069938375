import IconImport from '@mui/icons-material/CloudUpload';
import {Box, CircularProgress} from '@mui/material';
import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  FilterButton,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {useParams} from 'react-router';
import {
  CenterType,
  CenterTypeNames,
  ICenter,
  IUser,
  RoleType,
} from '../common/types';
import GMapField from '../components/GMapField';
import PreviewImage from '../components/PreviewImage';
import {Api, ApiError} from '../utils/api';

const CenterFilter = [<TextInput label="이름 지역" source="q" alwaysOn />];

const ListActions = () => {
  const {permissions} = usePermissions();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loadingImport, setLoadingImport] = React.useState(false);

  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      {permissions === RoleType.ADMIN && (
        <Button disabled={loadingImport} label="엑셀로 생성" component="label">
          <>
            {loadingImport ? (
              <CircularProgress size={18} thickness={2} />
            ) : (
              <IconImport />
            )}

            <input
              type="file"
              accept=".xlsx"
              hidden
              onChange={async (e: any) => {
                const file = e.target.files[0];
                var formData = new FormData();
                formData.append('file', file);

                try {
                  setLoadingImport(true);
                  const res = (await Api.post('/centers/upload', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },

                    timeout: 60 * 1000 * 5,
                  })) as any;

                  notify(res.message);
                } catch (err) {
                  const e = err as ApiError;
                  notify(e.message, {type: 'warning'});
                } finally {
                  e.target.value = ''; // 이 코드가 있어야 재업로드가 가능함?
                  refresh();
                  setLoadingImport(false);
                }
              }}
            />
          </>
        </Button>
      )}
    </TopToolbar>
  );
};

export const CenterList = () => (
  <List
    filters={CenterFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}
    actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="이름" source="name" />
      {/* <TextField label="픽스코어 storeCd" source="storeCd" /> */}
      <TextField label="전화번호" source="phone" />
      <TextField label="주소" source="address" />
      <NumberField
        label="소속트레이너"
        source="trainers.length"
        sortable={false}
      />
      <NumberField
        label="소속지공사"
        source="drillers.length"
        sortable={false}
      />
      <NumberField label="전화시도수" source="countCaller" />
      <NumberField label="조회수" source="countViewer" />
      <BooleanField label="캡슐" source="hasCapsuleEvent" />
      <BooleanField label="나인핀" source="hasNinepinEvent" />
      <BooleanField label="대회" source="hasCompetitionEvent" />
      <BooleanField label="이벤트" source="hasEventEvent" />
      <BooleanField label="해피타임" source="hasHappyTime" />
      <BooleanField label="프리게임" source="hasFreeGame" />
      <NumberField label="클럽수" source="countClub" />

      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField label="회원수정일" source="userUpdatedAt" showTime={true} />
      <BooleanField label="테스트" source="experiment" />
    </Datagrid>
  </List>
);

const CenterTitle = () => {
  const record = useRecordContext<ICenter>();
  return <span>센터 {record ? `"${record.name}"` : ''}</span>;
};

const CenterEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const CenterEdit = () => {
  const {id} = useParams<'id'>();

  const optionRenderer = (choice: IUser) => `${choice.name}(${choice.phone})`;

  return (
    <>
      <Box sx={{padding: 1}}>
        <a
          href="https://bocoder.tistory.com/91"
          target="_blank"
          rel="noreferrer">
          위도 경도 가져오기
        </a>
      </Box>
      <Edit title={<CenterTitle />}>
        <SimpleForm toolbar={<CenterEditToolbar />}>
          <Labeled sx={{marginBottom: 4}}>
            <DateField source="createdAt" label="생성일" showTime={true} />
          </Labeled>
          <Labeled sx={{marginBottom: 4}}>
            <DateField
              label="회원수정일"
              source="userUpdatedAt"
              showTime={true}
            />
          </Labeled>
          <BooleanInput label="테스트" source="experiment" />
          <Labeled sx={{marginBottom: 4}}>
            <ReferenceInput label="수정권한" source="author" reference="users">
              <AutocompleteInput
                optionText={optionRenderer}
                sx={{width: '65vw'}}
              />
            </ReferenceInput>
          </Labeled>
          {/* <TextInput
            label="픽스코어 storeCd"
            source="storeCd"
            fullWidth
            helperText="KCP storeCd"
          /> */}

          <TextInput label="이름" source="name" fullWidth />
          <TextInput label="전화번호" source="phone" fullWidth />
          <TextInput
            label="주소"
            source="address"
            fullWidth
            helperText="변경시 위도,경도 자동으로 바뀝니다"
          />
          <TextInput label="주소(상세)" source="addressDetail" fullWidth />
          <NumberInput
            label="위도(latitude)"
            source="coordinate.lat"
            fullWidth
            validate={[required()]}
            helperText="변경시 주소는 바뀌지 않습니다"
          />
          <NumberInput
            label="경도(longitude)"
            source="coordinate.lng"
            fullWidth
            validate={[required()]}
            helperText="변경시 주소는 바뀌지 않습니다"
          />
          <GMapField source="coordinate" />
          <SelectInput
            label="타입"
            source="type"
            choices={Object.values(CenterType).map(value => ({
              id: value,
              name: CenterTypeNames[value],
            }))}
            alwaysOn
          />
          <TextInput label="영업시간" source="openTime" fullWidth multiline />
          <TextInput label="이용요금" source="priceInfo" fullWidth multiline />
          <TextInput label="대화료" source="shoePrice" fullWidth />
          <NumberInput label="레인수" source="laneCount" fullWidth />
          <TextInput label="레인타입" source="laneTypeString" fullWidth />
          <TextInput label="핀세터" source="pinSetterTypeString" fullWidth />

          <TextInput
            label="정비시간"
            source="maintenanceTime"
            fullWidth
            multiline
          />
          <TextInput label="정비패턴명" source="patternName" fullWidth />
          <ImageInput
            source="patternPhoto"
            label="정비패턴 사진"
            accept="image/*">
            <PreviewImage source="src" />
          </ImageInput>
          <ArrayInput label="해피타임" source="happyTimePricings">
            <SimpleFormIterator>
              <TextInput label="제목" source="title" validate={[required()]} />
              <NumberInput
                label="가격"
                source="price"
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput label="프리게임" source="freeGamePricings">
            <SimpleFormIterator>
              <TextInput label="제목" source="title" validate={[required()]} />
              <NumberInput
                label="가격"
                source="price"
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput
            label="소개글(프로샵용)"
            source="proshopText"
            fullWidth
            multiline
          />

          <TextInput
            label="프로그램(프로샵용)"
            source="proshopMajorsText"
            fullWidth
            helperText="콤마(,)로 구분, 터보스위치/아이티지공/G&S스위치/이지그립"
          />

          <ImageInput source="cover" label="커버사진" accept="image/*">
            <PreviewImage source="src" />
          </ImageInput>

          <ImageInput source="photos" label="사진" accept="image/*" multiple>
            <PreviewImage source="src" />
          </ImageInput>
          <Labeled sx={{marginBottom: 4, marginTop: 4}}>
            <ReferenceManyField
              label="클럽"
              target="center"
              reference="center-clubs"
              sortable={false}
              filter={{center: id}}
              pagination={<Pagination />}
              fullWidth>
              <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField label="제목" source="title" />
                <ReferenceField
                  label="작성회원"
                  source="author"
                  reference="users">
                  <TextField source="name" />
                </ReferenceField>
                <BooleanField label="활성" source="enabled" />
                <DateField label="생성일" source="createdAt" showTime={true} />
                <DateField label="갱신일" source="updatedAt" showTime={true} />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
          <Labeled sx={{marginBottom: 4, marginTop: 4}}>
            <ReferenceManyField
              label="소속 트레이너"
              target="centers"
              reference="trainers"
              sortable={false}
              filter={{centers: id}}
              pagination={<Pagination />}
              fullWidth>
              <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField label="이름" source="name" />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
          <Labeled sx={{marginBottom: 4}}>
            <ReferenceManyField
              label="소속 프로샵"
              target="centers"
              reference="drillers"
              sortable={false}
              filter={{centers: id}}
              pagination={<Pagination />}
              fullWidth>
              <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField label="이름" source="name" />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
        </SimpleForm>
      </Edit>
    </>
  );
};

export const CenterCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="이름"
          source="name"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="주소"
          source="address"
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
