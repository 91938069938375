import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  ImageInput,
  Labeled,
  List,
  NumberField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useParams} from 'react-router';
import {
  CertificateCancelType,
  CertificateCancelTypeNames,
  CertificateState,
  CertificateStateNames,
  GenderTarget,
  GenderTargetNames,
  ILesson,
  LessonState,
  LessonStateNames,
  RoleType,
  WeekdayNames,
} from '../common/types';
import PreviewImage from '../components/PreviewImage';
import {toLessonDateString} from '../utils/stringUtils';
const LessonFilter = [
  <TextInput label="이름 구질" source="q" alwaysOn />,

  <SelectInput
    label="상태"
    source="state"
    choices={Object.values(LessonState).map(value => ({
      id: value,
      name: LessonStateNames[value],
    }))}
    alwaysOn
  />,
];
export const LessonList = () => (
  <List
    filters={LessonFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField label="트레이너" reference="trainers" source="trainer">
        <TextField source="name" />
      </ReferenceField>

      <TextField label="제목" source="title" />

      <ReferenceArrayField label="구질" reference="categories" source="pitches">
        <SingleFieldList>
          <ChipField size="small" source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <SelectField
        label="상태"
        source="state"
        choices={Object.values(LessonState).map(value => ({
          id: value,
          name: LessonStateNames[value],
        }))}
      />

      <NumberField label="참여인원" source="countMember" />
      <NumberField label="모집인원" source="acceptanceMemberCount" />
      <NumberField label="모집 최소 인원" source="acceptanceMemberMinCount" />

      <DateField
        label="모집마감일"
        source="acceptanceDateRange.to"
        showTime={true}
      />

      <BooleanField label="테스트계정" source="experiment" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <NumberField label="조회수" source="countViewer" />
    </Datagrid>
  </List>
);

const LessonTitle = () => {
  // const record = useRecordContext<ILesson>();
  return <span>그룹레슨</span>;
};

const LessonEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

const LessonInfoField = () => {
  const record = useRecordContext<ILesson>();
  return (
    <>
      <Box>
        요일: {record.weekdays.map(weekday => WeekdayNames[weekday]).join(', ')}
      </Box>
      <Box>
        수업기간: {dayjs(record.lessonDateRange?.from).format('YYYY-MM-DD')} ~{' '}
        {dayjs(record.lessonDateRange?.to).format('YYYY-MM-DD')}
      </Box>
      <Box>수업시간: {toLessonDateString(record)}</Box>
      <Box>수업횟수: {record.roundCount}</Box>
      <Box>
        모집기간: {dayjs(record.acceptanceDateRange?.from).format('YYYY-MM-DD')}{' '}
        ~ {dayjs(record.acceptanceDateRange?.to).format('YYYY-MM-DD')}
      </Box>
    </>
  );
};

export const LessonEdit = () => {
  const {id} = useParams<'id'>();

  return (
    <Edit title={<LessonTitle />}>
      <SimpleForm toolbar={<LessonEditToolbar />}>
        <CopyToClipboard
          text={`lessons/${id}`}
          onCopy={() => {
            alert('복사하였습니다.');
          }}>
          <span style={{cursor: 'pointer', border: '1px solid'}}>
            내부링크 복사
          </span>
        </CopyToClipboard>

        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="트레이너"
            reference="trainers"
            source="trainer">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="제목" source="title" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceArrayField
            label="프로그램"
            reference="categories"
            source="pitches">
            <SingleFieldList>
              <ChipField size="small" source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="상태"
            source="state"
            choices={Object.values(LessonState).map(value => ({
              id: value,
              name: LessonStateNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="참여인원" source="countMember" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="모집인원" source="acceptanceMemberCount" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField
            label="모집 최소 인원"
            source="acceptanceMemberMinCount"
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="성별"
            source="genderTarget"
            choices={Object.values(GenderTarget).map(value => ({
              id: value,
              name: GenderTargetNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="가격" source="price" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="설명" source="text" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            label="모집마감일"
            source="acceptanceDateRange.to"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="기타 등록정보">
          <LessonInfoField />
        </Labeled>

        <Labeled sx={{marginBottom: 4, marginTop: 4}}>
          <ReferenceManyField
            label="참여 인원"
            target="lesson"
            reference="certificates"
            sortable={false}
            filter={{lesson: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid bulkActionButtons={false}>
              <ReferenceField label="회원" reference="users" source="user">
                <TextField source="name" />
              </ReferenceField>

              <NumberField label="결제금액" source="paymentEntity.amount" />

              <NumberField
                label="환불금액"
                source="paymentEntity.refundAmount"
              />

              <SelectField
                label="수강상태"
                source="state"
                choices={Object.values(CertificateState).map(value => ({
                  id: value,
                  name: CertificateStateNames[value],
                }))}
              />

              <SelectField
                label="취소타입"
                source="cancelType"
                choices={Object.values(CertificateCancelType).map(value => ({
                  id: value,
                  name: CertificateCancelTypeNames[value],
                }))}
              />

              <DateField label="결제일" source="createdAt" showTime={true} />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>

        <ImageInput source="photos" label="갤러리" accept="image/*" multiple>
          <PreviewImage source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
