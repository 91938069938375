import Resizer from 'react-image-file-resizer';
import {Api} from './api';
import axios from 'axios';

export type ImageType =
  | 'PROFILE'
  | 'PHOTO'
  | 'LIST_COVER'
  | 'BANNER'
  | 'DETAIL'
  | 'ICON'
  | 'POPUP';

const resizeFile = (file: File, size: number) =>
  new Promise(resolve => {
    //
    // jimp.scaleToFit 와 같다.
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
      size < 1440 ? size : undefined, // 썸네일인 경우 최소사이즈를 기준으로 한다.
      size < 1440 ? size : undefined,
    );
  });

/**
 *
 * @param file
 * @param type
 * @returns key
 */
export const uploadImageAsync = async (file: File, type: ImageType) => {
  const response = (await Api.post('/signed-url', {
    type,
  })) as any;

  for (const info of response) {
    const image = await resizeFile(file, info.size);
    // 파일을 업로드합니다.
    await axios.put(info.url, image, {
      headers: {
        'Content-Type': 'image/jpeg',
      },
    });
  }

  return response[0].key;
};

export const uploadFaceswapImageAsync = async (file: File) => {
  const {urlInfo} = (await Api.post('/signed-faceswap-url')) as any;

  await axios.put(urlInfo.url, file, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });

  return urlInfo.key;
};

export const uploadFaceswapVideoAsync = async (file: File) => {
  const {urlInfo} = (await Api.post('/signed-faceswap-video-url')) as any;

  await axios.put(urlInfo.url, file, {
    headers: {
      'Content-Type': 'video/mp4',
    },
  });

  return urlInfo.key;
};
