import {FC, useEffect, useState} from 'react';
import {useInput} from 'react-admin';
import {IPickGameFrame} from '../common/types';
import Box from '@mui/material/Box';

const PickGameFrameInput: FC<any> = props => {
  const {
    field: {onChange, value},
  } = useInput(props);

  const [gameFrames, setGameFrames] = useState<IPickGameFrame[]>();

  useEffect(() => {
    if (!value || gameFrames) {
      return;
    }
    if (value.length === 0) {
      setGameFrames(
        Array(10).fill({
          firstPins: '',
          secondPins: '',
          thirdPins: '',
          score: 0,
        }),
      );
    } else {
      setGameFrames(value);
    }
  }, [value, gameFrames]);

  useEffect(() => {
    if (gameFrames) {
      onChange(gameFrames);
    }
  }, [gameFrames]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>프레임</th>
            <th>1구핀</th>
            <th>2구핀</th>
            <th>3구핀</th>
            <th>점수</th>
          </tr>
        </thead>
        <tbody>
          {gameFrames?.map((frame, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <input
                  value={frame.firstPins}
                  onChange={e => {
                    gameFrames[index] = {
                      ...gameFrames[index],
                      firstPins: e.target.value.trim(),
                    };
                    setGameFrames([...gameFrames]);
                  }}
                />
              </td>
              <td>
                <input
                  defaultValue={frame.secondPins}
                  onChange={e => {
                    gameFrames[index] = {
                      ...gameFrames[index],
                      secondPins: e.target.value.trim(),
                    };
                    setGameFrames([...gameFrames]);
                  }}
                />
              </td>
              <td>
                <input
                  defaultValue={frame.thirdPins}
                  onChange={e => {
                    gameFrames[index] = {
                      ...gameFrames[index],
                      thirdPins: e.target.value.trim(),
                    };
                    setGameFrames([...gameFrames]);
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  defaultValue={frame.score}
                  onChange={e => {
                    gameFrames[index] = {
                      ...gameFrames[index],
                      score: +e.target.value,
                    };
                    setGameFrames([...gameFrames]);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box sx={{fontSize: 10, color: 'blue'}}>
        <p>※ 핀 xxxxoxxxox 인 경우 5번핀 9번핀을 제외하고 넘어뜨림</p>
        <p>※ 치지 않았다면 비워주세요.</p>
        <p>(예1) 1프레임에서 1구에서 스트라이크면 2구는 비워주세요.</p>
        <p>(예2) 10프레임에서 2구에서 스페어처리를 못하면 3구는 비워주세요.</p>
      </Box>
    </>
  );
};

export default PickGameFrameInput;
