import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import {CategoryType, IMallProduct} from '../common/types';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';
import PreviewImage from '../components/PreviewImage';
import ThumbnailField from '../components/ThumbnailField';

const BulkActionButtons: FC<any> = props => (
  <>
    <MoveUpButton {...props} />
    <MoveDownButton {...props} />
  </>
);

export const MallProductList = () => {
  return (
    <List sort={{field: 'position', order: 'DESC'}} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButtons />}>
        <ThumbnailField label="사진" source="cover" />

        <ReferenceField
          label="브랜드"
          source="brandCategory"
          reference="categories">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="타입"
          source="typeCategory"
          reference="categories">
          <TextField source="name" />
        </ReferenceField>

        <TextField label="상품번호" source="productNumber" />
        <TextField label="제목" source="title" />

        <NumberField label="판매가격" source="price" />
        <NumberField label="재고" source="stock" />

        <NumberField label="조회수" source="countViewer" />
        <BooleanField label="활성" source="enabled" />

        <NumberField source="rating" label="평점" />

        <BooleanField label="추천" source="recommend" />
        <TextField label="추천위치" source="position" />
      </Datagrid>
    </List>
  );
};

const MallProductTitle = () => {
  const record = useRecordContext<IMallProduct>();
  return <span>쇼핑몰 상품 {record ? `"${record.title}"` : ''}</span>;
};

const MallProductEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const MallProductEdit = () => {
  return (
    <Edit title={<MallProductTitle />}>
      <SimpleForm toolbar={<MallProductEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField source="countRating" label="평가수" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField source="rating" label="평점" />
        </Labeled>

        <BooleanInput label="활성" source="enabled" />

        <BooleanInput label="관리자추천" source="recommend" />

        <ReferenceInput
          source="brandCategory"
          reference="categories"
          filter={{type: CategoryType.MALL_PRODUCT_BRAND}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput
            optionText="name"
            label="브랜드"
            validate={[required()]}
          />
        </ReferenceInput>

        <ReferenceInput
          source="typeCategory"
          reference="categories"
          filter={{type: CategoryType.MALL_PRODUCT_TYPE}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput
            optionText="name"
            label="볼 타입"
            validate={[required()]}
          />
        </ReferenceInput>

        <TextInput
          label="상품번호"
          source="productNumber"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="제목"
          source="title"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />

        {/* <NumberInput
          label="정가"
          source="priceOrigin"
          validate={[required()]}
        /> */}

        <NumberInput label="판매가격" source="price" validate={[required()]} />

        <NumberInput label="재고" source="stock" validate={[required()]} />

        <ImageInput
          source="cover"
          label="커버사진"
          accept="image/*"
          validate={[required()]}
          helperText="권장 사진크기 334 x 334">
          <PreviewImage source="src" />
        </ImageInput>

        <ImageInput
          source="photos"
          label="사진"
          accept="image/*"
          multiple
          validate={[required()]}>
          <PreviewImage source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const MallProductCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="brandCategory"
          reference="categories"
          filter={{type: CategoryType.MALL_PRODUCT_BRAND}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput
            optionText="name"
            label="브랜드"
            validate={[required()]}
          />
        </ReferenceInput>

        <ReferenceInput
          source="typeCategory"
          reference="categories"
          filter={{type: CategoryType.MALL_PRODUCT_TYPE}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput
            optionText="name"
            label="볼 타입"
            validate={[required()]}
          />
        </ReferenceInput>

        <TextInput
          label="상품번호"
          source="productNumber"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="제목"
          source="title"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />

        {/* <NumberInput
          label="정가"
          source="priceOrigin"
          validate={[required()]}
        /> */}
        <NumberInput label="판매가격" source="price" validate={[required()]} />

        {/* <NumberInput label="재고" source="stock" /> */}
      </SimpleForm>
    </Create>
  );
};
