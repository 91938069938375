import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import {RoleType} from '../common/types';

const CenterReviewFilter = [
  <ReferenceInput source="center" reference="centers" fullWidth alwaysOn>
    <AutocompleteInput optionText="name" label="센터" />
  </ReferenceInput>,
  <ReferenceInput
    source="user"
    reference="users"
    filter={{role: RoleType.USER}}
    fullWidth
    alwaysOn>
    <AutocompleteInput optionText="name" label="작성회원" />
  </ReferenceInput>,
];

export const CenterReviewList = () => (
  <List
    filters={CenterReviewFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField label="센터" source="center" reference="centers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="작성회원" source="user" reference="users">
        <TextField source="name" />
      </ReferenceField>

      <TextField label="내용" source="text" />
      <NumberField label="평가" source="rating" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField label="갱신일" source="updatedAt" showTime={true} />
    </Datagrid>
  </List>
);

//
// Edit
const CenterReviewTitle = () => {
  return <span>리뷰</span>;
};

const CenterReviewEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const CenterReviewEdit = () => {
  return (
    <Edit title={<CenterReviewTitle />}>
      <SimpleForm toolbar={<CenterReviewEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="updatedAt" label="갱신일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="센터" source="center" reference="centers">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="작성회원" source="user" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <BooleanInput label="활성" source="enabled" />

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="평가" source="rating" />
        </Labeled>

        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
