import {useState} from 'react';
import {
  Confirm,
  SaveButton,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {useFormContext} from 'react-hook-form';
import {IMallOrder, MallOrderState} from '../../common/types';
import {Api} from '../../utils/api';

const MallOrderStateButton = () => {
  const mallOrder = useRecordContext<IMallOrder>();
  const {getValues} = useFormContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const doCommand = async () => {
    try {
      const {id, state} = getValues();
      setIsLoading(true);

      switch (state) {
        case MallOrderState.PAID:
          await Api.put(`/mall-orders/${id}/packing`);
          break;
        case MallOrderState.PACKING:
          await Api.put(`/mall-orders/${id}/preparing`);
          break;
        case MallOrderState.PREPARING:
          await Api.put(`/mall-orders/${id}/shipping`);
          break;
        case MallOrderState.SHIPPING:
          await Api.put(`/mall-orders/${id}/arrived`);
          break;
        case MallOrderState.ARRIVED:
          await Api.put(`/mall-orders/${id}/closed`);
          break;
        case MallOrderState.CLOSED:
          break;
        case MallOrderState.CANCELED:
          break;
      }

      refresh();
    } catch (err: any) {
      notify(err.message, {type: 'warning'});
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  let allowCommand = false;
  let commandTitle = '';

  switch (mallOrder.state) {
    case MallOrderState.PAID:
      allowCommand = true;
      commandTitle = '상품준비중으로 변경';
      break;
    case MallOrderState.PACKING:
      allowCommand = true;
      commandTitle = '배송준비중으로 변경';
      break;
    case MallOrderState.PREPARING:
      allowCommand = true;
      commandTitle = '배송중으로 변경';
      break;
    case MallOrderState.SHIPPING:
      allowCommand = true;
      commandTitle = '배송완료로 변경';
      break;
    case MallOrderState.ARRIVED:
      allowCommand = true;
      commandTitle = '구매확정완료로 변경';
      break;
    case MallOrderState.CLOSED:
      allowCommand = false;
      break;
    case MallOrderState.CANCELED:
      allowCommand = false;
      break;
    // case MallOrderState.REFUNDED
  }

  if (!allowCommand) {
    return null;
  }

  return (
    <>
      <SaveButton
        alwaysEnable
        label={commandTitle}
        type="button"
        sx={{marginX: 1}}
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      />

      <Confirm
        isOpen={open}
        loading={isLoading}
        title="주문 상태변경"
        content={`${commandTitle}?`}
        onConfirm={doCommand}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default MallOrderStateButton;
