import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  ImageInput,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import 'react-photo-view/dist/react-photo-view.css';
// import {useParams} from 'react-router';
import Box from '@mui/material/Box';
import {FC} from 'react';
import ReactPlayer from 'react-player';
import {
  GalleryReviewState,
  GalleryReviewStateNames,
  GalleryType,
  GalleryTypeNames,
  IGallery,
  RoleType,
} from '../common/types';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';
import PreviewImage from '../components/PreviewImage';
import VideoUploader from '../components/VideoUploader';
import ListHelper from '../components/ListHelper';
//
const GalleryFilter = [
  <SelectInput
    label="상태"
    source="reviewState"
    choices={Object.values(GalleryReviewState).map(value => ({
      id: value,
      name: GalleryReviewStateNames[value],
    }))}
    alwaysOn
  />,
];

const BulkActionButtons: FC<any> = props => (
  <>
    <Box sx={{fontSize: 12, color: 'grey'}}>
      추천위치 내림차순 ↓ 상태에서 위/아래 변경 가능
    </Box>
    <Box sx={{fontSize: 10, color: 'red'}}>
      추천위치 큰 값일수록 상단에 위치
    </Box>

    <MoveUpButton {...props} />
    <MoveDownButton {...props} />
  </>
);

export const GalleryList = () => (
  <List
    filters={GalleryFilter}
    sort={{field: 'recommendPosition', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButtons />}>
      <ReferenceField label="회원" reference="users" source="user">
        <TextField source="name" />
      </ReferenceField>

      <SelectField
        label="타입"
        source="type"
        choices={Object.values(GalleryType).map(value => ({
          id: value,
          name: GalleryTypeNames[value],
        }))}
      />

      <SelectField
        label="상태"
        source="reviewState"
        choices={Object.values(GalleryReviewState).map(value => ({
          id: value,
          name: GalleryReviewStateNames[value],
        }))}
      />

      <BooleanField label="볼닥앤플" source="allowEdit" />

      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />

      <NumberField label="조회수" source="countViewer" />

      <BooleanField label="추천" source="recommend" />
      <NumberField label="추천위치" source="recommendPosition" />
    </Datagrid>
  </List>
);

const GalleryTitle = () => {
  return <span>영상</span>;
};

const GalleryEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

const GalleryVideoField = () => {
  const gallery = useRecordContext<IGallery>();
  return (
    <>
      {gallery.videoInfo && (
        <ReactPlayer url={gallery.videoInfo.video} controls />
      )}
      <VideoUploader source="videoInfo" />
    </>
  );
};

export const GalleryEdit = () => {
  // const {id} = useParams<'id'>();
  return (
    <>
      <ListHelper
        defaultOpen={true}
        contents={[
          {
            title: '관리자 볼닥앤플 비허용',
            text: `활성화하면 볼닥앤플이 꺼지고 파트너는 볼닥앤플 허용에 대해 수정 불가`,
          },
          {
            title: '관리자 볼닥앤플 비허용 사유보내기',
            text: `관리자 볼닥앤플 비허용 상태에서 거절메시지를 저장하면 아래 폼으로 푸시 보냅니다.(검토 상태는 승인상태여도 상관 없음)
title: '컨텐츠 검수결과',
message: 볼닥앤플 허용이 불가합니다.
사유: ......,
            `,
          },
        ]}
      />
      <Edit title={<GalleryTitle />}>
        <SimpleForm toolbar={<GalleryEditToolbar />}>
          <Labeled sx={{marginBottom: 4}}>
            <ReferenceField label="회원" reference="users" source="user">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>

          <BooleanInput
            label="관리자 볼닥앤플 비허용"
            source="adminDisallowEdit"
          />

          <Labeled sx={{marginBottom: 4}}>
            <BooleanField label="볼닥앤플" source="allowEdit" />
          </Labeled>

          <TextInput label="내용" source="text" multiline fullWidth />

          <BooleanInput label="관리자추천" source="recommend" />

          <Labeled sx={{marginBottom: 4}}>
            <SelectField
              label="상태"
              source="reviewState"
              choices={Object.values(GalleryReviewState).map(value => ({
                id: value,
                name: GalleryReviewStateNames[value],
              }))}
            />
          </Labeled>

          <SelectInput
            label="검토상태"
            source="reviewState"
            choices={Object.values(GalleryReviewState).map(value => ({
              id: value,
              name: GalleryReviewStateNames[value],
            }))}
          />

          <Labeled sx={{marginBottom: 4}}>
            <TextInput
              label="거절메시지"
              source="rejectComment"
              fullWidth
              helperText="변경 후 저장하면 회원에게 메시지를 보냅니다"
            />
          </Labeled>

          <FormDataConsumer>
            {({formData}) => {
              if (formData.type === GalleryType.PHOTO) {
                return (
                  <ImageInput label="사진" source="photo" accept="image/*">
                    <PreviewImage source="src" />
                  </ImageInput>
                );
              } else {
                return <GalleryVideoField />;
              }
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};
