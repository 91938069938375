import {Day, ID} from '.';

export enum PaymentType {
  CERTIFICATE_TRAINER = 'certificate-trainer', // CertificateType.TRIAL, CertificateType.SINGLE_LESSON, CertificateType.GROUP_LESSON,
  CERTIFICATE_LESSON = 'certificate-lesson',
  CERTIFICATE_DRILLER = 'certificate-driller',
  MEMBERSHIP = 'membership',
  MOVIE = 'movie',
  MALL_PRODUCT = 'mall-product',
}
export const PaymentTypeNames = {
  'certificate-trainer': '맛보기 수업',
  'certificate-lesson': '그룹 레슨',
  'certificate-driller': '맛보기 지공',
  membership: '파트너멤버십',
  movie: '영상',
  'mall-product': '쇼핑몰 상품',
};

export enum PaymentState {
  IDLE = 'idle',
  SUCCESSED = 'successed',
  REFUNDED = 'refunded',
  CANCELED = 'canceled', // 입금하기도 전에 취소
}

export const PaymentStateNames = {
  idle: '미결제',
  successed: '결제완료',
  refunded: '환불',
  canceled: '결제취소',
};

export enum PaymentMethod {
  FREE = 'free',
  CARD = 'card',
  ISP = 'isp',
  VBANK = 'vbank',
  KCP_CARD = 'kcp_card',
  KCP_ISP = 'kcp_isp',
}

export const PaymentMethodName = {
  free: '무료',
  card: '이노페이-등록카드',
  isp: '이노페이-일반카드',
  vbank: '가상계좌',
  kcp_card: 'KCP-등록카드',
  kcp_isp: 'KCP-일반카드',
};

export enum PaymentSettlementState {
  IDLE = 'idle',
  READY = 'ready',
  COMPLETED = 'completed',
}

export const PaymentSettlementStateNames = {
  idle: '정산대기',
  ready: '정산예정',
  completed: '정산완료',
};

export interface IPayment {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  number: string;

  type: PaymentType;
  state: PaymentState;
  method: PaymentMethod;

  trainer?: ID;
  settlementState?: PaymentSettlementState;
  settlementAt?: Date;
  settlementRate?: number;
  settlementAmount?: number;
  settlementExpectAmount?: number;

  user: ID;

  certificate?: ID;
  membership?: ID;
  movie?: ID;
  mallOrders?: ID[];

  title: string;
  amount: number;
  refundAmount: number;
  discountAmount: number;

  tid?: string; // 이노페이 tid

  vbankInfo?: {
    amount: number;
    vbankAccountName: string;
    vbankExpDate: Day;
    vbankName: string;
    vbankNum: string;
  };
}
