import Box from '@mui/material/Box';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  ImageInput,
  Labeled,
  List,
  NumberField,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useParams} from 'react-router';
import {
  CategoryType,
  GenderTarget,
  GenderTargetNames,
  ITrainer,
  MembershipState,
  MembershipStateNames,
  MembershipTarget,
  MembershipTargetNames,
  MembershipType,
  MembershipTypeNames,
  RoleType,
} from '../common/types';
import ExprieDateField from '../components/ExpireDateField';
import PreviewImage from '../components/PreviewImage';
import Section from '../components/Section';
import {TextArrayField} from '../components/TextArrayField';
import ThumbnailField from '../components/ThumbnailField';
import {Api, ApiError} from '../utils/api';
import {toPrice} from '../utils/stringUtils';

const TrainerFilter = [
  <TextInput label="이름 구질" source="q" alwaysOn />,
  <BooleanInput label="활성" source="enabled" />,
  <BooleanInput label="승인" source="certified" />,
  <BooleanInput label="검수요청" source="inspecting" />,
  <BooleanInput label="테스트계정" source="experiment" />,
];

export const TrainerList = () => (
  <List
    filters={TrainerFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ThumbnailField label="사진" source="virtual.cover" />

      <TextField label="이름" source="name" />

      <ReferenceArrayField label="센터" reference="centers" source="centers">
        <SingleFieldList>
          <ChipField size="small" source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <SelectField
        label="멤버십타입"
        source="membershipType"
        choices={Object.values(MembershipType).map(value => ({
          id: value,
          name: MembershipTypeNames[value],
        }))}
      />
      <SelectField
        label="멤버십타겟"
        source="membershipTarget"
        choices={Object.values(MembershipTarget).map(value => ({
          id: value,
          name: MembershipTargetNames[value],
        }))}
      />
      <ExprieDateField label="멤버십만료일" source="membershipExpireAt" />
      <DateField label="멤버십다음결제일" source="membershipPayAt" />
      <DateField label="검수요청일" source="inspectingAt" showTime={true} />
      <BooleanField label="검수요청" source="inspecting" />
      <BooleanField label="승인" source="certified" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField label="회원수정일" source="userUpdatedAt" showTime={true} />
      <NumberField label="판매개수" source="saleCount" />
      <NumberField label="전화시도수" source="countCaller" />
      <NumberField label="조회수" source="countViewer" />
      <BooleanField label="테스트계정" source="experiment" />
    </Datagrid>
  </List>
);

const TrainerTitle = () => {
  const record = useRecordContext<ITrainer>();
  return <span>트레이너 {record ? `"${record.name}"` : ''}</span>;
};

const TrainerEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

const PricingsField = () => {
  const record = useRecordContext<ITrainer>();
  return (
    <>
      {record.pricings?.map((pricing, index) => (
        <Box key={index}>
          <Box component="span">{pricing.round}회 / </Box>
          <Box component="span">{toPrice(pricing.price)}원</Box>
        </Box>
      ))}
    </>
  );
};

const GroupPricingsField = () => {
  const record = useRecordContext<ITrainer>();
  return (
    <>
      {record.groupLessons?.map((groupLesson, index) => (
        <Box key={index}>
          <Box component="span">[{groupLesson.headCount}명]</Box>
          {groupLesson.pricings?.map((pricing, index) => (
            <Box key={index}>
              <Box component="span">{pricing.round}회 / </Box>
              <Box component="span">{toPrice(pricing.price)}원</Box>
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

const OpenHoursField = () => {
  const record = useRecordContext<ITrainer>();
  return (
    <>
      {record.weekdayRange && (
        <Box>
          평일: {record.weekdayRange.from} ~ {record.weekdayRange.to}
        </Box>
      )}
      {record.saturdayRange && (
        <Box>
          토요일: {record.saturdayRange.from} ~ {record.saturdayRange.to}
        </Box>
      )}
      {record.sundayRange && (
        <Box>
          일요일: {record.sundayRange.from} ~ {record.sundayRange.to}
        </Box>
      )}
      {record.holidays && <Box>휴무일: {record.holidays.join(', ')}</Box>}
      <Box>참고사항: {record.openHours}</Box>
    </>
  );
};

const UnsubscribeMembership = () => {
  const {permissions} = usePermissions();
  const record = useRecordContext<ITrainer>();
  const notify = useNotify();
  const refresh = useRefresh();
  if (!record.membershipPayAt) return null;
  return (
    <button
      type="button"
      style={{cursor: 'pointer', border: '1px solid'}}
      onClick={async () => {
        if (permissions !== RoleType.ADMIN) {
          return window.alert('권한이 없습니다.');
        }
        if (
          window.confirm(
            '멤버십 정기결제 취소하시겠습니까? 재구독은 앱에서만 가능합니다.',
          )
        ) {
          try {
            (await Api.put(
              `/memberships/trainer/unsubscribe?userId=${record.user}`,
            )) as any;

            refresh();
            notify('취소 성공하였습니다.');
          } catch (err) {
            const e = err as ApiError;
            notify(e.message, {type: 'warning'});
          }
        }
      }}>
      멤버십 정기결제 취소
    </button>
  );
};

const TrainerDeeplink = () => {
  const trainer = useRecordContext<ITrainer>();
  if (trainer.deepLink) {
    return (
      <div>
        <span>앱링크: </span>
        <CopyToClipboard
          text={trainer.deepLink}
          onCopy={() => {
            alert('복사하였습니다.');
          }}>
          <button
            type="button"
            style={{cursor: 'pointer', border: '1px solid'}}>
            {trainer.deepLink}
          </button>
        </CopyToClipboard>
      </div>
    );
  }

  return null;
};

export const TrainerEdit = () => {
  const {id} = useParams<'id'>();

  // const [link, setLink] = useState('');

  return (
    <Edit title={<TrainerTitle />}>
      <SimpleForm toolbar={<TrainerEditToolbar />}>
        <TrainerDeeplink />
        {/* 
        <div>
          <button
            type="button"
            style={{cursor: 'pointer', border: '1px solid'}}
            onClick={async () => {
              const res = (await Api.get(`/trainers/${id}/link`)) as any;
              setLink(res.link);
            }}>
            앱링크(구)가져오기
          </button>

          {link && (
            <CopyToClipboard
              text={link}
              onCopy={() => {
                alert('복사하였습니다.');
              }}>
              <button
                type="button"
                style={{cursor: 'pointer', border: '1px solid'}}>
                {link}
              </button>
            </CopyToClipboard>
          )}
        </div> */}

        <CopyToClipboard
          text={`trainers/${id}`}
          onCopy={() => {
            alert('복사하였습니다.');
          }}>
          <span style={{cursor: 'pointer', border: '1px solid'}}>
            내부링크 복사
          </span>
        </CopyToClipboard>

        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField
            source="userUpdatedAt"
            label="회원수정일"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <BooleanField label="활성" source="enabled" />
        </Labeled>

        <DateField source="inspectingAt" label="검수요청일" showTime={true} />
        <BooleanInput
          label="검수요청"
          source="inspecting"
          helperText="비활성되어야 회원이 다시 검수요청할 수 있습니다."
        />

        <BooleanInput label="승인" source="certified" />

        <Labeled sx={{marginBottom: 4}}>
          <TextInput
            label="거절메시지"
            source="rejectMessage"
            fullWidth
            helperText="변경 후 저장하면 회원에게 메시지를 보냅니다"
          />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="회원" source="user" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Section title="멤버십" />

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="멤버십타입"
            source="membershipType"
            choices={Object.values(MembershipType).map(value => ({
              id: value,
              name: MembershipTypeNames[value],
            }))}
          />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="멤버십타겟"
            source="membershipTarget"
            choices={Object.values(MembershipTarget).map(value => ({
              id: value,
              name: MembershipTargetNames[value],
            }))}
          />
        </Labeled>
        <DateInput
          label="만료일"
          source="membershipExpireAt"
          helperText="멤버십 만료후 변경시 가장 최근 만료된 멤버십으로 연장(카톡X), 만료전이면 현재 멤버십 그대로 연장(카톡알림)"
        />
        <div>
          <Labeled sx={{marginBottom: 4}}>
            <DateField label="다음결제일" source="membershipPayAt" />
          </Labeled>
          <UnsubscribeMembership />
        </div>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceManyField
            label="멤버십 히스토리"
            target="trainer"
            reference="memberships"
            filter={{trainer: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid bulkActionButtons={false}>
              <SelectField
                label="멤버십타입"
                source="type"
                choices={Object.values(MembershipType).map(value => ({
                  id: value,
                  name: MembershipTypeNames[value],
                }))}
              />
              <SelectField
                label="멤버십타겟"
                source="target"
                choices={Object.values(MembershipTarget).map(value => ({
                  id: value,
                  name: MembershipTargetNames[value],
                }))}
              />
              <SelectField
                label="상태"
                source="state"
                choices={Object.values(MembershipState).map(value => ({
                  id: value,
                  name: MembershipStateNames[value],
                }))}
              />

              <NumberField label="결제금액" source="paymentEntity.amount" />
              <NumberField
                label="환불금액"
                source="paymentEntity.refundAmount"
              />
              <NumberField label="이노페이TID" source="paymentEntity.tid" />
              <NumberField label="주문번호" source="paymentEntity.number" />

              <DateField label="구매일" source="createdAt" showTime={true} />
              <DateField label="만료일" source="expireAt" />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>

        <Section title="정보" />

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceArrayInput
            label="센터"
            source="centers"
            reference="centers"
            fullWidth>
            <AutocompleteArrayInput optionText="name" label="센터" />
          </ReferenceArrayInput>
        </Labeled>
        <TextInput label="이름" source="name" fullWidth />

        <ImageInput source="photos" label="사진" accept="image/*" multiple>
          <PreviewImage source="src" />
        </ImageInput>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="설명" source="text" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectInput
            label="성별"
            source="genderTarget"
            validate={[required()]}
            choices={Object.values(GenderTarget).map(value => ({
              id: value,
              name: GenderTargetNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceArrayInput
            label="프로그램"
            reference="categories"
            source="majorPitches"
            filter={{type: CategoryType.PITCH}}>
            <AutocompleteArrayInput optionText="name" label="스타일" />
          </ReferenceArrayInput>
        </Labeled>

        <ImageInput
          source="majorPhotos"
          label="프로그램사진"
          accept="image/*"
          multiple>
          <PreviewImage source="src" />
        </ImageInput>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="프로그램설명" source="majorText" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <BooleanField label="체험제공" source="serviceTrial" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="체험제공가격" source="serviceTrialPrice" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField
            label="체업제공가격할인율"
            source="serviceTrialDiscountRate"
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="개인레슨 가격">
          <PricingsField />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="개인레슨 1회 진행 시간" source="pricingInfoText" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="개인레슨가격 참고사항" source="pricingText" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="그룹레슨 가격">
          <GroupPricingsField />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="그룹레슨 1회 진행 시간" source="groupInfoText" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="그룹레슨가격 참고사항" source="groupText" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="운영 시간">
          <OpenHoursField />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="자격사항">
          <TextArrayField source="qualifications" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="이력사항">
          <TextArrayField source="carriers" />
        </Labeled>

        <ImageInput
          source="certifiedPhotos"
          label="상주확인서"
          accept="image/*"
          multiple>
          <PreviewImage source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
