import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  ImageInput,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import {
  CertificateState,
  CertificateStateNames,
  IUser,
  RoleType,
} from '../common/types';
import PreviewImage from '../components/PreviewImage';
import ThumbnailField from '../components/ThumbnailField';

const ReviewFilter = [
  <ReferenceInput
    source="partner"
    reference="users"
    fullWidth
    alwaysOn
    filter={{activePartner: true}}>
    <AutocompleteInput optionText="name" label="파트너" />
  </ReferenceInput>,
  <ReferenceInput
    source="user"
    reference="users"
    filter={{role: RoleType.USER}}
    fullWidth
    alwaysOn>
    <AutocompleteInput
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="작성회원"
    />
  </ReferenceInput>,
];

export const ReviewList = () => (
  <List
    filters={ReviewFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ThumbnailField label="영수증" source="receipt" />
      <ReferenceField label="트레이너" source="trainer" reference="trainers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="작성회원" source="user" reference="users">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="결제"
        source="certificate"
        reference="certificates"
        link={false}>
        <SelectField
          source="state"
          choices={Object.values(CertificateState).map(value => ({
            id: value,
            name: CertificateStateNames[value],
          }))}
        />
      </ReferenceField>

      <TextField label="내용" source="text" />
      <NumberField label="평가" source="rating" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField label="갱신일" source="updatedAt" showTime={true} />
    </Datagrid>
  </List>
);

//
// Edit
const ReviewTitle = () => {
  return <span>리뷰</span>;
};

const ReviewEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const ReviewEdit = () => {
  return (
    <Edit title={<ReviewTitle />}>
      <SimpleForm toolbar={<ReviewEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="updatedAt" label="갱신일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="트레이너"
            source="trainer"
            reference="trainers">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="작성회원" source="user" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <BooleanInput label="활성" source="enabled" />

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="평가" source="rating" />
        </Labeled>

        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />

        <ImageInput source="receipt" label="영수증" accept="image/*">
          <PreviewImage source="src" />
        </ImageInput>

        <Labeled sx={{marginTop: 4, marginBottom: 4}}>
          <DateField source="repliedAt" label="답글일시" showTime={true} />
        </Labeled>

        <TextInput label="트레이너 답글" source="reply" multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
};
