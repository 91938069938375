import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';

export const PickRankList = () => (
  <List sort={{field: 'averageScore', order: 'DESC'}} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <NumberField label="순위" source="rank" sortable={false} />
      <ReferenceField
        label="회원"
        reference="users"
        source="userEntity.id"
        sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="평균점수" source="averageScore" />
      <NumberField label="총게임수" source="totalGameCount" />
    </Datagrid>
  </List>
);
