import {ID, IGameSection} from '.';

export enum TemplateTag {
  //
  // SMS
  AUTH_PHONE = 'auth-phone',

  //
  // LMS
  LESSON_CLOSED_PARTNER = 'lesson-closed-partner',

  //
  // Email
  TEMPORARY_PASSWORD = 'temporary-password',

  //
  // Game
  GAME_A = 'game-a',
  GAME_B = 'game-b',
  GAME_C = 'game-c',

  //
  // Alimatalk
  CERTIFICATE_TRIAL_APPLY_PARNTER = 'certificate-trial-apply-partner',
  CERTIFICATE_TRIAL_APPLY_USER = 'certificate-trial-apply-user',
  CERTIFICATE_TRIAL_CLOSED_USER = 'certificate-trial-closed-user',
  CERTIFICATE_TRIAL_AUTO_CANCELED_PARNTER = 'certificate-trial-auto-canceled-partner',
  CERTIFICATE_TRIAL_AUTO_CANCELED_USER = 'certificate-trial-auto-canceled-user',
  CERTIFICATE_TRIAL_AUTO_CANCEL_WARN_PARNTER = 'certificate-trial-auto-cancel-warn-partner',
  CERTIFICATE_TRIAL_AUTO_CANCEL_WARN_USER = 'certificate-trial-auto-cancel-warn-user',
  CERTIFICATE_TRIAL_CANCELED_PARNTER = 'certificate-trial-canceled-partner',
  CERTIFICATE_TRIAL_CANCELED_USER = 'certificate-trial-canceled-user',

  CERTIFICATE_DRILLER_APPLY_PARNTER = 'certificate-driller-apply-partner',
  CERTIFICATE_DRILLER_APPLY_USER = 'certificate-driller-apply-user',
  CERTIFICATE_DRILLER_CLOSED_USER = 'certificate-driller-closed-user',
  CERTIFICATE_DRILLER_AUTO_CANCELED_PARNTER = 'certificate-driller-auto-canceled-partner',
  CERTIFICATE_DRILLER_AUTO_CANCELED_USER = 'certificate-driller-auto-canceled-user',
  CERTIFICATE_DRILLER_AUTO_CANCEL_WARN_PARNTER = 'certificate-driller-auto-cancel-warn-partner',
  CERTIFICATE_DRILLER_AUTO_CANCEL_WARN_USER = 'certificate-driller-auto-cancel-warn-user',
  CERTIFICATE_DRILLER_CANCELED_PARNTER = 'certificate-driller-canceled-partner',
  CERTIFICATE_DRILLER_CANCELED_USER = 'certificate-driller-canceled-user',

  CERTIFICATE_LESSON_APPLY_USER = 'certificate-lesson-apply-user',
  CERTIFICATE_LESSON_CLOSED_USER = 'certificate-lesson-closed-user',
  CERTIFICATE_LESSON_CANCLED_USER = 'certificate-lesson-canceled-user',

  PARTNER_GREETING = 'partner-greeting',
  MEMBERSHIP_WARN_PAY = 'membership-warn-pay',

  MEMBERSHIP_PAID = 'membership-paid',
  MEMBERSHIP_PAY_1_FAILED = 'membership-pay-1-failed',
  MEMBERSHIP_PAY_2_FAILED = 'membership-pay-2-failed',

  MEMBERSHIP_CERTIFIED = 'membership-certified',
  MEMBERSHIP_FREE_PERIOD = 'membership-free-period',

  CHATROOM_NEW_PARTNER = 'chatroom-new-partner',
  CHATROOM_REMINDER_PARTNER = 'chatroom-reminder-partner',
  CHATROOM_NEW_USER = 'chatroom-new-user',
  CHATROOM_REMINDER_USER = 'chatroom-reminder-user',

  CERTIFICATE_NORMAL_APPLY_PARNTER = 'certificate-normal-apply-partner',
  CERTIFICATE_NORMAL_APPLY_USER = 'certificate-normal-apply-user',
  CERTIFICATE_NORMAL_FIRST_CLOSED_USER = 'certificate-normal-first-closed-user',
  // CERTIFICATE_NORMAL_LAST_CLOSED_USER = "certificate-normal-last-closed-user", // <-- 반려되어 사용 안함
  CERTIFICATE_NORMAL_AUTO_CANCELED_PARNTER = 'certificate-normal-auto-canceled-partner',
  CERTIFICATE_NORMAL_AUTO_CANCELED_USER = 'certificate-normal-auto-canceled-user',
  CERTIFICATE_NORMAL_CANCELED_PARNTER = 'certificate-normal-canceled-partner',
  CERTIFICATE_NORMAL_CANCELED_USER = 'certificate-normal-canceled-user',

  ORDER_PAID = 'order-paid',
  ORDER_SHIPPING = 'order-shipping',
  ORDER_CONFRIM = 'order-confirm',
  ORDER_REVIEW = 'order-review',
}
// https://bowldoc.page.link/#{링크}
export const TemplateTagNames = {
  'auth-phone': '전화번호 인증',
  'temporary-password': '임시비밀번호 발급알림',
  'lesson-closed-partner': '그룹레슨-모집종료-파트너', //BD0018 <-- 불가

  'game-a': '이벤트 A',
  'game-b': '이벤트 B',
  'game-c': '이벤트 C',

  'certificate-trial-apply-partner': '수강권-1일체험레슨-신청-파트너', //BD0001
  'certificate-trial-apply-user': '수강권-1일체험레슨-신청-회원', //BD0002
  'certificate-trial-closed-user': '수강권-1일체험레슨-종료-회원', //BD0003
  'certificate-trial-auto-canceled-partner':
    '수강권-1일체험레슨-자동취소-파트너', //BD0004
  'certificate-trial-auto-canceled-user': '수강권-1일체험레슨-자동취소-회원', //BD0005
  'certificate-trial-canceled-partner': '수강권-1일체험레슨-직접취소-파트너', //BD0006
  'certificate-trial-canceled-user': '수강권-1일체험레슨-직접취소-회원', //BD0007

  'certificate-driller-apply-partner': '수강권-1일체험지공-신청-파트너', //BD0008
  'certificate-driller-apply-user': '수강권-1일체험지공-신청-회원', //BD0009
  'certificate-driller-closed-user': '수강권-1일체험지공-종료-회원', //BD0010

  'certificate-driller-auto-canceled-partner':
    '수강권-1일체험지공-자동취소-파트너', //BD0011
  'certificate-driller-auto-canceled-user': '수강권-1일체험지공-자동취소-회원', //BD0012
  'certificate-driller-canceled-partner': '수강권-1일체험지공-직접취소-파트너', //BD0013
  'certificate-driller-canceled-user': '수강권-1일체험지공-직접취소-회원', //BD0014

  'certificate-lesson-apply-user': '수강권-그룹레슨-신청-회원', //BD0015
  'certificate-lesson-closed-user': '수강권-그룹레슨-종료-회원', //BD0016
  'certificate-lesson-canceled-user': '수강권-그룹레슨-모집취소-회원', //BD0017

  'partner-greeting': '파트너-환영', //BD0019->BD1019
  'membership-warn-pay': '멤버십-결제-7일전-경고', //BD0020
  'membership-paid': '멤버십-결제완료', //BD0021

  'membership-pay-1-failed': '멤버십-결제실패-3일전', //BD0022
  'membership-pay-2-failed': '멤버십-결제실패-만료일', //BD0023->BD1023

  'membership-certified': '멤버십-승인', //BD0024
  'membership-free-period': '멤버십-무료기간연장', //BD0025

  'chatroom-new-partner': '채팅-메시지-도착-파트너', //BD0026
  'chatroom-reminder-partner': '채팅-메시지-리마인드-파트너', //BD0027
  'chatroom-new-user': '채팅-메시지-도착-회원', //BD0028
  'chatroom-reminder-user': '채팅-메시지-리마인드-회원', //BD0029

  'certificate-normal-apply-partner': '수강권-일반레슨-신청-파트너', //BD0030
  'certificate-normal-apply-user': '수강권-일반레슨-신청-회원', //BD0031
  'certificate-normal-first-closed-user': '수강권-일반레슨-첫번째-종료-회원', //BD0032
  // "certificate-normal-last-closed-user": "수강권-일반레슨-마지막-종료-회원", //BD0033 <-- 반려되어 사용 안함
  'certificate-normal-auto-canceled-partner': '수강권-일반레슨-자동취소-파트너', //BD0034
  'certificate-normal-auto-canceled-user': '수강권-일반레슨-자동취소-회원', //BD0035
  'certificate-normal-canceled-partner': '수강권-일반레슨-직접취소-파트너', //BD0036
  'certificate-normal-canceled-user': '수강권-일반레슨-직접취소-회원', //BD0037

  'certificate-trial-auto-cancel-warn-partner':
    '수강권-1일체험레슨-자동취소-경고-파트너', //BD0038
  'certificate-trial-auto-cancel-warn-user':
    '수강권-1일체험레슨-자동취소-경고-회원', //BD0039
  'certificate-driller-auto-cancel-warn-partner':
    '수강권-1일체험지공-자동취소-경고-파트너', //BD0040
  'certificate-driller-auto-cancel-warn-user':
    '수강권-1일체험지공-자동취소-경고-회원', //BD0041

  'order-paid': '주문-결제완료', //BD0042
  'order-shipping': '주문-배송중', //BD0043
  'order-confirm': '주문-구매확정', //BD0044
  'order-review': '주문-리뷰요청', //BD0045
};

export enum TemplateType {
  ALIMTALK = 'alimtalk',
  SMS = 'sms',
  EMAIL = 'email',
  GAME = 'game',
}

export const TemplateTypeNames = {
  alimtalk: '알림톡',
  sms: '문자',
  email: '이메일',
  game: '이벤트',
};
export interface ITemplate {
  id: ID;

  type: TemplateType;
  tag: TemplateTag;

  title: string;
  text: string;
  html?: string;
  code?: string;

  link?: string;
  buttons?: string;

  sections: IGameSection[];
}
