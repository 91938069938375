import {ID, Photo} from '.';

export enum CenterEventType {
  CAPSULE = 'capsule',
  NINEPIN = 'ninepin',
  COMPETITION = 'competition',
  EVENT = 'event',
  ETC = 'etc',
}

export const CenterEventTypeNames = {
  capsule: '캡슐',
  ninepin: '나인핀',
  competition: '대회',
  event: '이벤트',
  etc: '기타',
};

export interface ICenterEvent {
  id: ID;
  createdAt: Date;
  updatedAt: Date;

  enabled: boolean;

  author: ID;
  center: ID;

  type: CenterEventType;

  title: string;
  content: string;

  cover: Photo;
  contentPhotos: Photo[];
}
