import {ID} from '.';

export enum ReportResource {
  USER = 'users',
  CENTER = 'centers',
  CENTER_AUTHOR = 'center-author',
  CENTER_UPDATE = 'center-update',
  GALLERY = 'gallery',
}

export const ReportResourceNames = {
  users: '회원',
  centers: '센터',
  'center-author': '센터관리자등록',
  'center-update': '센터수정',
  gallery: '파트너컨텐츠',
};

export interface IReport {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  user: ID;

  resource: ReportResource;
  ref: ID;

  text: string;

  check: boolean;
}
