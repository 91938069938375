// https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f#file-axios-refresh_token-2-js

import axios, {AxiosInstance, AxiosResponse} from 'axios';
import packageInfo from '../../package.json';

const shouldIntercept = (error: any) => {
  try {
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

const setTokenData = async (tokenData: any, axiosClient: any) => {
  localStorage.setItem('accessToken', tokenData.accessToken);
  localStorage.setItem('refreshToken', tokenData.refreshToken);
};

const handleTokenRefresh = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
        {
          refreshToken,
        },
        {
          headers: {
            app: 'admin',
            version: packageInfo.version,
          },
        },
      )
      .then(({data}) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const attachTokenToRequest = (request: any, token: string) => {
  request.headers['Authorization'] = 'Bearer ' + token;
};

const handler = (axiosClient: AxiosInstance, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue: any = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error: any) => {
    if (!options.shouldIntercept(error)) {
      if (error.response?.status === 500) {
        alert('알 수 없는 서버 오류입니다ㅠㅠ');
        return Promise.reject({
          status: 500,
          message: '알 수 없는 서버 오류입니다ㅠㅠ',
        });
      } else {
        return Promise.reject({
          status: error.response?.status,
          message: error.response?.data.message,
          field: error.response?.data.field,
          details: error.response?.data.details,
        });
      }
    }
    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }
    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({resolve, reject});
      })
        .then((token: any) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch(err => {
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then(async (tokenData: any) => {
          await options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.accessToken);
          processQueue(null, tokenData.accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch(err => {
          localStorage.removeItem('accessToken');
          window.location.replace('/#/login');
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    interceptor,
  );
};

export default handler;
