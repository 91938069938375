import {
  DateRange,
  GenderTarget,
  HourRange,
  ICenter,
  ID,
  Photo,
  Weekday,
} from '.';

export enum LessonType {
  GROUP = 'group',
}
export const LessonTypeNames = {
  group: '그룹레슨',
};

export enum LessonState {
  ACCEPTANCE = 'acceptance',
  CLOSED = 'closed',
  CANCELED = 'canceled',
}

export const LessonStateNames = {
  acceptance: '모집중',
  closed: '모집종료',
  canceled: '모집취소',
};

export interface ILesson {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  type: LessonType;
  state: LessonState;

  title: string;

  user: ID;
  trainer: ID;

  experiment: boolean;
  enabled: boolean;
  editable: boolean;

  weekdays: Weekday[];

  hourRage?: HourRange;

  lessonDateRange?: DateRange;

  roundCount: number; // 수업횟수

  pitches: ID[];

  genderTarget: GenderTarget;

  countMember: number;

  acceptanceMemberCount: number; //모집인원
  acceptanceMemberMinCount?: number; //모집 최소 인원
  acceptanceDateRange?: DateRange; //모집기간
  acceptanceDays: number;

  price: number; // 가격

  text: string;

  photos: Photo[];

  countViewer: number;
  countBookmarker: number;

  centerDocs: ICenter[];

  userEntity?: {
    id: ID;
    photo?: Photo;
    name: string;
  };

  trainerEntity?: {
    id: ID;
    name: string;
    photos: Photo[];
  };

  virtual?: {
    cover?: string;
    read: boolean;
    bookmark: boolean;
  };
}
