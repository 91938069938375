import * as React from 'react';
import {
  Datagrid,
  DateField,
  Edit,
  List,
  required,
  SaveButton,
  SelectField,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {RoleType} from '../common/types';
import {
  IPolicy,
  PolicyType,
  PolicyTypeNames,
} from '../common/types/Policy.type';

//
// List
export const PolicyList = () => (
  <List sort={{field: 'type', order: 'DESC'}} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <SelectField
        label="타입"
        source="type"
        choices={Object.values(PolicyType).map(value => ({
          id: value,
          name: PolicyTypeNames[value],
        }))}
      />

      <DateField label="수정날짜" source="updatedAt" showTime={true} />
    </Datagrid>
  </List>
);

//
// Edit
const PolicyTitle = () => {
  const record = useRecordContext<IPolicy>();
  return (
    <span>
      {record ? `"${PolicyTypeNames[record.type as PolicyType]}"` : ''}
    </span>
  );
};

const PolicyEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const PolicyEdit = () => (
  <Edit title={<PolicyTitle />}>
    <SimpleForm toolbar={<PolicyEditToolbar />}>
      <SelectField
        label="타입"
        source="type"
        choices={Object.values(PolicyType).map(value => ({
          id: value,
          name: PolicyTypeNames[value],
        }))}
        fullWidth
      />

      <TextInput
        source="text"
        maxRows={30}
        validate={[required()]}
        multiline
        fullWidth
      />
    </SimpleForm>
  </Edit>
);
