import * as React from 'react';
import {
  ChipField,
  DateField,
  Edit,
  ImageInput,
  Labeled,
  NumberField,
  ReferenceArrayField,
  SaveButton,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import {IProduct} from '../common/types';
import PreviewImage from '../components/PreviewImage';

const ProductTitle = () => {
  const record = useRecordContext<IProduct>();
  return <span>프로샵 상품 {record ? `"${record.title}"` : ''}</span>;
};

const ProductEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const ProductEdit = () => {
  return (
    <Edit title={<ProductTitle />}>
      <SimpleForm toolbar={<ProductEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceArrayField
            label="카테고리"
            reference="categories"
            source="categories">
            <SingleFieldList>
              <ChipField size="small" source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Labeled>

        <TextInput label="이름" source="title" fullWidth />

        <ImageInput source="photos" label="사진" accept="image/*" multiple>
          <PreviewImage source="src" />
        </ImageInput>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="설명" source="text" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="가격" source="price" />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
