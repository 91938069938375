import {ID, Photo, VideoInfo} from '.';

export enum GalleryType {
  VIDEO = 'video',
  PHOTO = 'photo',
}

export const GalleryTypeNames = {
  video: '비디오',
  photo: '사진',
};

export enum GalleryReviewState {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export const GalleryReviewStateNames = {
  pending: '대기',
  accepted: '승인',
  rejected: '거절',
};

export interface IGallery {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  user: ID;
  enabled: boolean;

  type: GalleryType;

  reviewState: GalleryReviewState;
  rejectComment?: string;

  recommend: boolean;
  recommendPosition?: number;

  text: string;
  allowEdit: boolean;
  adminDisallowEdit: boolean;

  videoInfo?: VideoInfo;
  photo?: Photo;

  countViewer: number;

  userEntity?: {
    id: ID;
    photo?: Photo;
    name: string;
    trainer: ID;
  };

  virtual?: {
    read: boolean;
  };
}
