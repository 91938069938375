import * as React from 'react';
import {FC} from 'react';
import {Button, Box} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {Collapse} from 'react-collapse';

type Props = {
  contents: {title: string; text: string}[];
  defaultOpen?: boolean;
};

const ListHelper: FC<Props> = ({contents, defaultOpen = false}) => {
  // const classes = useStyles();

  const [openHelp, setOpenHelp] = React.useState(defaultOpen);

  return (
    <>
      <Box sx={{textAlign: 'right'}}>
        <Button
          color="primary"
          aria-label="upload picture"
          component="span"
          size="small"
          onClick={async () => {
            setOpenHelp(!openHelp);
          }}
          sx={{marginLeft: 'auto'}}>
          <HelpIcon fontSize="small" />
          <span>도움말</span>
        </Button>
      </Box>
      <Collapse isOpened={openHelp}>
        <Box
          sx={{
            background: '#F4F4F4',
          }}>
          {contents.map(({title, text}, index) => (
            <Box key={index} sx={{marginBottom: 1}}>
              <Box
                component="h1"
                sx={{
                  color: '#0068FF',
                  fontSize: 12,
                  fontWeight: 'bold',
                  padding: 0,
                  margin: 0,
                }}>
                {title}
              </Box>
              <Box
                component="p"
                sx={{
                  whiteSpace: 'pre-wrap',
                  fontSize: 12,
                  color: 'black',
                  padding: 0,
                  margin: 0,
                }}>
                {text}
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

export default ListHelper;
