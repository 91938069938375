import {FC} from 'react';
import {
  ArrayField,
  AutocompleteInput,
  Datagrid,
  DateField,
  Edit,
  Labeled,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  useRecordContext,
} from 'react-admin';
import {useParams} from 'react-router';
import {
  ChatType,
  ChatTypeNames,
  ChatroomType,
  ChatroomTypeNames,
  ChatroomUserState,
  ChatroomUserStateNames,
  IUser,
} from '../common/types';
import useApp from '../hooks/useApp';

const ChatroomFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(ChatroomType).map(value => ({
      id: value,
      name: ChatroomTypeNames[value],
    }))}
    alwaysOn
  />,
  <ReferenceInput source="users" reference="users" fullWidth alwaysOn>
    <AutocompleteInput
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="회원"
    />
  </ReferenceInput>,
];

export const ChatroomList = () => (
  <List
    filters={ChatroomFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <SelectField
        label="타입"
        source="type"
        choices={Object.values(ChatroomType).map(value => ({
          id: value,
          name: ChatroomTypeNames[value],
        }))}
      />

      <ReferenceField label="회원1" source="users.0" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="회원2" source="users.1" reference="users">
        <TextField source="name" />
      </ReferenceField>

      <TextField
        label="마지막메시지"
        source="tailMessage"
        style={{
          display: 'inline-block',
          maxWidth: '20em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField
        label="마지막메시지전송일"
        source="tailMessageAt"
        showTime={true}
      />
    </Datagrid>
  </List>
);

const ChatMessageField: FC<any> = ({source}) => {
  const record = useRecordContext();
  const {s3Url} = useApp();

  if (record['type'] === ChatType.PHOTO && s3Url) {
    const photoUrl = `${s3Url}/${record[source]}`;
    return (
      <a href={photoUrl} target="_blank" rel="noreferrer">
        사진보기
      </a>
    );
  }
  return (
    <span
      style={{
        display: 'inline-block',
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
      {record[source]}
    </span>
  );
};

//
// Edit
const ChatroomTitle = () => {
  return <span>채팅방</span>;
};

export const ChatroomEdit = () => {
  const {id} = useParams<'id'>();
  return (
    <Edit title={<ChatroomTitle />}>
      <SimpleForm toolbar={false}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="updatedAt" label="갱신일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="회원1" source="users.0" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="회원2" source="users.1" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ArrayField label="채팅상태정보" source="userInfos">
            <Datagrid bulkActionButtons={false}>
              <ReferenceField label="참여자" reference="users" source="user">
                <TextField source="name" />
              </ReferenceField>
              <NumberField label="안읽은 채팅 수" source="unreadCount" />
              <SelectField
                label="상태"
                source="state"
                choices={Object.values(ChatroomUserState).map(value => ({
                  id: value,
                  name: ChatroomUserStateNames[value],
                }))}
              />
              <DateField
                label="채팅룸에 들어온 일자"
                source="enteredAt"
                showTime
              />
              <DateField
                label="채팅룸에서 나간 일자"
                source="exitedAt"
                showTime
              />
              <DateField label="채팅룸 삭제일" source="terminatedAt" showTime />
              <DateField
                label="채팅룸에 최종 있었던 일자(heartbeat)"
                source="heartbeatAt"
                showTime
              />

              <NumberField
                label="평균응답시간(분)"
                source="averageResponseMinutes"
              />
            </Datagrid>
          </ArrayField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceManyField
            label="채팅"
            target="chatroom"
            reference="chats"
            sortable={false}
            filter={{chatroom: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label="보낸회원"
                source="sender"
                reference="users">
                <TextField source="name" />
              </ReferenceField>
              <SelectField
                label="타입"
                source="type"
                choices={Object.values(ChatType).map(value => ({
                  id: value,
                  name: ChatTypeNames[value],
                }))}
              />

              <ChatMessageField
                label="메시지"
                source="message"
                sortable={false}
              />

              <DateField label="보낸시간" source="timestamp" showTime={true} />
              <DateField
                label="응답요구시간"
                source="reqeustResponseDate"
                showTime={true}
              />
              <NumberField
                label="이전 상대 대화 응답시간(분)"
                source="responseMinutes"
              />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
