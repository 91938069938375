import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateProps,
  Datagrid,
  DeleteButton,
  Edit,
  ImageInput,
  List,
  NumberField,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {RoleType} from '../common/types';
import {IBanner} from '../common/types/Banner.type';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';
import PreviewImage from '../components/PreviewImage';
import ThumbnailField from '../components/ThumbnailField';

const BulkActionButtons: FC<any> = props => (
  <>
    <MoveUpButton {...props} />
    <MoveDownButton {...props} />
  </>
);

export const BannerList = () => {
  const {permissions} = usePermissions();

  return (
    <List sort={{field: 'position', order: 'DESC'}} exporter={false}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          permissions === RoleType.ADMIN ? <BulkActionButtons /> : false
        }>
        <ThumbnailField label="사진" source="photo" />

        <TextField label="이름" source="name" />
        <NumberField label="조회수" source="countViewer" />

        <BooleanField label="활성" source="enabled" />
        <TextField label="위치" source="position" />
      </Datagrid>
    </List>
  );
};

//
// Edit
const BannerTitle = () => {
  const record = useRecordContext<IBanner>();

  return <span>배너 {record ? `"${record.name}"` : ''}</span>;
};

const BannerEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const BannerEdit = () => (
  <Edit title={<BannerTitle />}>
    <SimpleForm toolbar={<BannerEditToolbar />}>
      <TextInput
        label="이름"
        source="name"
        validate={[required()]}
        fullWidth
        helperText="이름은 앱에 노출되지 않는 관리용입니다."
      />

      <TextInput
        label="경로"
        source="url"
        fullWidth
        helperText="트레이너,지공사,그룹레슨, 상세 상단에 '내부링크 복사'를 이용 / https 또는 http가 포함되어 있으면 외부로 이동"
      />

      <BooleanInput label="활성" source="enabled" />
      <ImageInput
        label="사진"
        source="photo"
        accept="image/*"
        helperText="사진크기 1080 x 160">
        <PreviewImage source="src" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

//
// Create
export const BannerCreate: FC<CreateProps> = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="이름"
          source="name"
          validate={[required()]}
          fullWidth
          helperText="이름은 앱에 노출되지 않는 관리용입니다."
        />
        <TextInput
          label="경로"
          source="url"
          fullWidth
          helperText="트레이너,지공사,그룹레슨, 상세 상단에 '내부링크 복사'를 이용 / https 또는 http가 포함되어 있으면 외부로 이동"
        />
      </SimpleForm>
    </Create>
  );
};
