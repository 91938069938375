import {
  Edit,
  Labeled,
  NumberField,
  ReferenceField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {useParams} from 'react-router';
import {
  GenderType,
  GenderTypeNames,
  HandType,
  HandTypeNames,
  IParticipation,
  RoleType,
} from '../common/types';
import useParticipation from '../hooks/useParticipation';

const ParticipationTitle = () => {
  const record = useRecordContext<IParticipation>();
  return <span>이벤트 참여자 {record ? `"${record.name}"` : ''}</span>;
};

const ParticipationEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
        </>
      )}
    </Toolbar>
  );
};

export const ParticipationEdit = () => {
  const {id} = useParams<'id'>();

  const {particiation} = useParticipation({
    id,
  });

  const redirect = particiation ? `/games/${particiation.game}` : false;

  return (
    <Edit title={<ParticipationTitle />} redirect={redirect}>
      <SimpleForm toolbar={<ParticipationEditToolbar />}>
        <TextInput
          label="이름"
          source="name"
          fullWidth
          validate={[required()]}
        />

        <SelectInput
          label="성별"
          source="gender"
          choices={Object.values(GenderType).map(value => ({
            id: value,
            name: GenderTypeNames[value],
          }))}
          validate={[required()]}
        />

        <TextInput
          label="생년월일"
          source="birthday"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="전화번호"
          source="phone"
          fullWidth
          validate={[required()]}
        />

        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="구력" source="career" fullWidth />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <NumberField label="에버리지" source="average" fullWidth />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="사용손"
            source="hand"
            choices={Object.values(HandType).map(value => ({
              id: value,
              name: HandTypeNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="스타일"
            source="pitchStyle"
            reference="categories"
            link={false}>
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
