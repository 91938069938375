import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  ImageInput,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
// import {useParams} from 'react-router';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';
import {
  IMovie,
  MovieState,
  MovieStateNames,
  MovieType,
  MovieTypeNames,
  RoleType,
} from '../common/types';
import PreviewImage from '../components/PreviewImage';
import Section from '../components/Section';
const MovieFilter = [
  <SelectInput
    label="상태"
    source="state"
    choices={Object.values(MovieState).map(value => ({
      id: value,
      name: MovieStateNames[value],
    }))}
    alwaysOn
  />,
];
export const MovieList = () => (
  <List
    filters={MovieFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField label="회원" reference="users" source="user">
        <TextField source="name" />
      </ReferenceField>

      <SelectField
        label="상태"
        source="state"
        choices={Object.values(MovieState).map(value => ({
          id: value,
          name: MovieStateNames[value],
        }))}
      />

      <SelectField
        label="타입"
        source="type"
        choices={Object.values(MovieType).map(value => ({
          id: value,
          name: MovieTypeNames[value],
        }))}
      />

      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <NumberField label="결제금액" source="paymentEntity.amount" />
    </Datagrid>
  </List>
);

const MovieTitle = () => {
  return <span>영상</span>;
};

const MovieEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

const MovieInfoField = () => {
  const movie = useRecordContext<IMovie>();
  return (
    <>
      <Box>내 사진</Box>
      <Box>
        <PhotoProvider>
          <PhotoView src={movie.sourceImage}>
            <img
              src={movie.sourceImage}
              style={{objectFit: 'cover', width: 300}}
              alt=""
            />
          </PhotoView>
        </PhotoProvider>
      </Box>

      <Box>트레이너 컨텐츠</Box>
      <Box>
        {movie.sourceVideo && <ReactPlayer url={movie.sourceVideo} controls />}
        {movie.sourceImage2 && (
          <PhotoProvider>
            <PhotoView src={movie.sourceImage2}>
              <img
                src={movie.sourceImage2}
                style={{objectFit: 'cover', width: 300}}
                alt=""
              />
            </PhotoView>
          </PhotoProvider>
        )}
      </Box>

      <Box>결과물</Box>
      {movie.resultMedia && (
        <Box>
          <PhotoProvider>
            <PhotoView src={movie.resultThumb}>
              <img
                src={movie.resultThumb}
                style={{objectFit: 'cover', width: 300}}
                alt=""
              />
            </PhotoView>
          </PhotoProvider>
          {movie.type === MovieType.VIDEO && (
            <ReactPlayer url={movie.resultMedia} controls />
          )}
        </Box>
      )}
      <Box>워터마크</Box>
      {movie.watermarkedMedia && (
        <Box>
          {movie.type === MovieType.PHOTO && (
            <PhotoProvider>
              <PhotoView src={movie.watermarkedMedia}>
                <img
                  src={movie.watermarkedMedia}
                  style={{objectFit: 'cover', width: 300}}
                  alt=""
                />
              </PhotoView>
            </PhotoProvider>
          )}
          {movie.type === MovieType.VIDEO && (
            <ReactPlayer url={movie.watermarkedMedia} controls />
          )}
        </Box>
      )}
    </>
  );
};

export const MovieEdit = () => {
  // const {id} = useParams<'id'>();

  return (
    <Edit title={<MovieTitle />}>
      <SimpleForm toolbar={<MovieEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="회원" reference="users" source="user">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField
            label="파트너 컨텐츠"
            reference="galleries"
            source="gallery">
            <TextField source="text" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="타입"
            source="type"
            choices={Object.values(MovieType).map(value => ({
              id: value,
              name: MovieTypeNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField source="requestAt" label="생성요청일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            source="generatingStartAt"
            label="생성시작일"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            source="generatingEndAt"
            label="생성종료일"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="상태"
            source="state"
            choices={Object.values(MovieState).map(value => ({
              id: value,
              name: MovieStateNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}} label="정보">
          <MovieInfoField />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export const MovieCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <Section title="내 사진" />
        <ImageInput label="사진" source="sourceImage" accept="image/*">
          <PreviewImage source="src" />
        </ImageInput>

        <Section title="합성할 대상" />
        <p>원본 영상/원본 이미지 둘 중 하나만 저장</p>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <FileInput
            source="sourceVideo"
            label="원본 영상 (.mp4)"
            accept="video/mp4">
            <FileField source="src" title="title" />
          </FileInput>
          <ImageInput
            label="원본 이미지"
            source="sourceImage2"
            accept="image/*">
            <PreviewImage source="src" />
          </ImageInput>
        </Box>
      </SimpleForm>
    </Create>
  );
};
