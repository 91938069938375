import axios from 'axios';
import React, {FC, useEffect, useState} from 'react';
import {useInput} from 'react-admin';
import VideoThumbnail from 'react-video-thumbnail';
import useApp from '../hooks/useApp';
import {Api} from '../utils/api';

const VideoUploader: FC<any> = props => {
  const {faceswapUrl} = useApp();

  const {
    field: {onChange},
  } = useInput(props);

  const [status, setStatus] = useState<
    'initial' | 'uploading' | 'success' | 'fail'
  >('initial');

  const [file, setFile] = useState<File>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [thumbnail, setThumbnail] = useState<string>();
  const [duration, setDuration] = useState<number>();
  const [size, setSize] = useState<number>();

  useEffect(() => {
    if (videoUrl && thumbnail && duration && size) {
      onChange({
        video: videoUrl,
        thumb: thumbnail,
        duration,
        size,
      });
    }
  }, [videoUrl, thumbnail, duration, size]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      var f = e.target.files[0];
      setStatus('initial');
      setFile(f);
      setSize(f.size);
      setThumbnail(undefined);
      setVideoUrl(undefined);
    }
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    if (file) {
      setStatus('uploading');
      try {
        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          setDuration(video.duration * 1000);
        };

        video.src = URL.createObjectURL(file);
        const {urlInfo} = (await Api.post('/signed-faceswap-video-url')) as any;
        await axios.put(urlInfo.url, file, {
          headers: {
            'Content-Type': 'video/mp4',
          },
        });

        setVideoUrl(`${faceswapUrl}/${urlInfo.key}`);

        setStatus('success');
      } catch (error) {
        console.error(error);
        setStatus('fail');
      }
    }
  };

  return (
    <>
      <div className="input-group">
        <label htmlFor="file">비디오 선택</label>
        <input
          id="file"
          type="file"
          accept="video/mp4"
          onChange={handleFileChange}
        />
      </div>
      {file && (
        <section>
          비디오 상세:
          <ul>
            <li>이름: {file.name}</li>
            <li>크기: {size} bytes</li>
            {duration && <li>길이: {duration / 1000} 초</li>}
          </ul>
        </section>
      )}
      {file && !thumbnail && (
        <button onClick={handleUpload}>비디오 클라우드에 업로드</button>
      )}
      <Result status={status} />
      {videoUrl && (
        <VideoThumbnail
          videoUrl={videoUrl}
          snapshotAtTime={0}
          // renderThumbnail={false}
          thumbnailHandler={async (thumbnail: any) => {
            const {urlInfo} = (await Api.post('/signed-faceswap-url')) as any;

            //
            // base64 to blob
            var byteString = atob(thumbnail.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ab], {type: 'image/jpeg'});
            //
            // upload to aws
            await axios.put(urlInfo.url, blob, {
              headers: {
                'Content-Type': 'image/jpeg',
              },
            });
            setThumbnail(`${faceswapUrl}/${urlInfo.key}`);
          }}
        />
      )}
    </>
  );
};

const Result = ({status}: {status: string}) => {
  if (status === 'success') {
    return (
      <p>
        ✅ 비디오가 클라우드에 업로드 했습니다. 하단 저장버튼을 눌러야 모든
        데이터가 저장됩니다.
      </p>
    );
  } else if (status === 'fail') {
    return <p>❌ 비디오 업로드 실패</p>;
  } else if (status === 'uploading') {
    return <p>⏳ 비디오 업로드중.</p>;
  } else {
    return null;
  }
};

export default VideoUploader;
