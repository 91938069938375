import {useEffect, useState} from 'react';
import {ISetting} from '../common/types';
import {Api} from '../utils/api';
import {create} from 'zustand';

interface _SettingState {
  setting?: ISetting;
  setSetting: (setting: ISetting) => void;
}

const _useSettingContext = create<_SettingState>((set, _get) => ({
  setSetting: setting => {
    set({setting});
  },
}));

const useSetting = () => {
  const [loading, setLoading] = useState(false);
  const {setting, setSetting} = _useSettingContext();

  useEffect(() => {
    if (!setting) {
      _fetchSetting();
    }
  }, []);

  const _fetchSetting = async () => {
    setLoading(true);
    try {
      const data = await Api.get<any, any>('/settings/one');
      setSetting(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    setting,
  };
};

export default useSetting;
