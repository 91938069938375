import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateProps,
  Datagrid,
  DeleteButton,
  Edit,
  ImageInput,
  List,
  NumberField,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {RoleType} from '../common/types';
import {IPopupNotice} from '../common/types/PopupNotice.type';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';
import PreviewImage from '../components/PreviewImage';
import ThumbnailField from '../components/ThumbnailField';

const BulkActionButtons: FC<any> = props => (
  <>
    <MoveUpButton {...props} />
    <MoveDownButton {...props} />
  </>
);

export const PopupNoticeList = () => {
  const {permissions} = usePermissions();

  return (
    <List sort={{field: 'position', order: 'DESC'}} exporter={false}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          permissions === RoleType.ADMIN ? <BulkActionButtons /> : false
        }>
        <ThumbnailField label="사진" source="photo" />

        <TextField label="이름" source="name" />
        <NumberField label="조회수" source="countViewer" />

        <BooleanField label="활성" source="enabled" />
        <TextField label="위치" source="position" />
      </Datagrid>
    </List>
  );
};

//
// Edit
const PopupNoticeTitle = () => {
  const record = useRecordContext<IPopupNotice>();

  return <span>팝업공지 {record ? `"${record.name}"` : ''}</span>;
};

const PopupNoticeEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const PopupNoticeEdit = () => (
  <Edit title={<PopupNoticeTitle />}>
    <SimpleForm toolbar={<PopupNoticeEditToolbar />}>
      <TextInput
        label="제목"
        source="name"
        validate={[required()]}
        fullWidth
        helperText="앱에서 자세히보기 선택시 상단 타이틀에 표시됩니다."
      />

      <TextInput
        label="경로"
        source="url"
        fullWidth
        helperText="https 또는 http가 포함되어있어야 함."
      />

      <BooleanInput label="활성" source="enabled" />
      <ImageInput
        label="사진"
        source="photo"
        accept="image/*"
        helperText="권장 사진크기 960 x 960">
        <PreviewImage source="src" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

//
// Create
export const PopupNoticeCreate: FC<CreateProps> = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="제목"
          source="name"
          validate={[required()]}
          fullWidth
          helperText="앱에서 자세히보기 선택시 상단 타이틀에 표시됩니다."
        />
        <TextInput
          label="경로"
          source="url"
          fullWidth
          helperText="https 또는 http가 포함되어 있어야 함."
        />
      </SimpleForm>
    </Create>
  );
};
