import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import IconExport from '@mui/icons-material/GetApp';
import {CircularProgress} from '@mui/material';
import axios from 'axios';
import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  Labeled,
  Link,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {useParams} from 'react-router';
import {
  GameGenderTarget,
  GameGenderTargetNames,
  GameState,
  GameStateNames,
  GameType,
  GameTypeNames,
  GenderType,
  GenderTypeNames,
  HandType,
  HandTypeNames,
  IGame,
  RoleType,
  TemplateType,
} from '../common/types';
import useApp from '../hooks/useApp';
import {Api} from '../utils/api';
import {fileDownload} from '../utils/fileDownload';

export const GameList = () => (
  <List sort={{field: 'createdAt', order: 'DESC'}} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="제목" source="title" />
      <DateField label="경기일" source="gameAt" showTime />
      <NumberField label="지원자 수" source="countMember" />
      <DateField label="모집시작일" source="acceptanceDateRange.from" />
      <DateField label="모집종료일" source="acceptanceDateRange.to" />
      <SelectField
        label="모집상태"
        source="state"
        choices={Object.values(GameState).map(value => ({
          id: value,
          name: GameStateNames[value],
        }))}
      />
      <BooleanField label="활성" source="enabled" />
    </Datagrid>
  </List>
);

const GameTitle = () => {
  const record = useRecordContext<IGame>();
  return <span>이벤트 {record ? `"${record.title}"` : ''}</span>;
};

export const DownloadExcelButton = (props: any) => {
  const record = useRecordContext(props);
  const [loading, setLoading] = React.useState(false);

  if (!record) return null;
  return (
    <Button
      sx={{marginLeft: 'auto'}}
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          const res = await Api.get(
            `/participations?download=true&offset=0&limit=0&filter=${encodeURIComponent(
              JSON.stringify({
                game: record.id,
              }),
            )}`,
            {
              timeout: 60 * 1000,
              responseType: 'blob',
            },
          );
          const filename = `지원자.xlsx`;
          fileDownload(res, filename);
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }}
      label="지원자 엑셀">
      {loading ? <CircularProgress size={18} thickness={2} /> : <IconExport />}
    </Button>
  );
};

const GameEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
          <DownloadExcelButton />
        </>
      )}
    </Toolbar>
  );
};

export const DownloadButton = (props: any) => {
  const record = useRecordContext(props);
  const {s3Url} = useApp();

  const [loading, setLoading] = React.useState(false);
  if (!record) return null;
  return (
    <Button
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          const pdfUrl = `${s3Url}/${record.agreement}`;
          const blob = await axios.get(pdfUrl, {
            responseType: 'blob',
          });
          fileDownload(blob.data, `${record.name}.pdf`);
        } catch (err) {
          // notify(err, "warning");
        } finally {
          setLoading(false);
        }
      }}
      label="촬영동의서">
      {loading ? (
        <CircularProgress size={18} thickness={2} />
      ) : (
        <DownloadIcon />
      )}
    </Button>
  );
};

export const EditButton = (props: any) => {
  const record = useRecordContext(props);
  if (!record) return null;
  return <Link to={`/participations/${record.id}`}>수정</Link>;
};

export const GameEdit = () => {
  const {id} = useParams<'id'>();

  return (
    <Edit title={<GameTitle />}>
      <SimpleForm toolbar={<GameEditToolbar />}>
        <BooleanInput label="활성" source="enabled" />
        <BooleanInput label="촬영동의서필요" source="isNeedAgreement" />
        <TextInput
          label="제목"
          source="title"
          fullWidth
          placeholder="[유튜브] 서울 오란다볼링파크 팀 대항전"
          validate={[required()]}
        />
        <TextInput
          label="서브제목"
          source="subtitle"
          placeholder="스트로커 - 크랭커 - 덤리스 - 투핸드 팀당 3명, 서든데스 경기"
          fullWidth
          validate={[required()]}
        />

        <DateTimeInput label="경기일시" source="gameAt" />

        <DateInput
          label="모집시작일"
          source="acceptanceDateRange.from"
          validate={[required()]}
        />
        <DateInput
          label="모집종료일"
          source="acceptanceDateRange.to"
          validate={[required()]}
        />

        <ReferenceInput source="center" reference="centers" fullWidth>
          <AutocompleteInput optionText="name" label="센터" />
        </ReferenceInput>

        <TextInput label="공지사항" source="notice" fullWidth multiline />

        <SelectInput
          label="타입"
          source="type"
          choices={Object.values(GameType).map(value => ({
            id: value,
            name: GameTypeNames[value],
          }))}
          validate={[required()]}
        />

        <TextInput
          label="상금"
          source="prizeText"
          placeholder="총 상금 1000만원"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="가격"
          source="priceText"
          placeholder="참가비 50,000원"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="에버리지 제한"
          source="averageText"
          placeholder="AVG 제한없음"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="경기형식"
          source="matchFormat"
          placeholder="팀 서바이벌"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="복장"
          source="wear"
          placeholder="단체복"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="정비패턴"
          source="pattern"
          placeholder="볼닥패턴"
          fullWidth
          validate={[required()]}
        />

        <SelectInput
          label="성별"
          source="genderTarget"
          choices={Object.values(GameGenderTarget).map(value => ({
            id: value,
            name: GameGenderTargetNames[value],
          }))}
          validate={[required()]}
        />

        <ArrayInput label="내용" source="sections">
          <SimpleFormIterator>
            <TextInput
              label="제목"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              label="내용"
              source="body"
              validate={[required()]}
              multiline
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceManyField
            label="지원자"
            target="game"
            reference="participations"
            sortable={false}
            filter={{game: id}}
            pagination={<Pagination />}
            fullWidth>
            <Datagrid>
              <ReferenceField label="회원" source="user" reference="users">
                <TextField source="name" />
              </ReferenceField>
              <TextField label="이름" source="name" />
              <TextField label="생년월일" source="birthday" />
              <BooleanField label="성인" source="isAdult" />

              <SelectField
                label="성별"
                source="gender"
                choices={Object.values(GenderType).map(value => ({
                  id: value,
                  name: GenderTypeNames[value],
                }))}
              />

              <TextField label="전화번호" source="phone" />

              <NumberField label="구력" source="career" />
              <NumberField label="에버리지" source="average" />

              <SelectField
                label="사용손"
                source="hand"
                choices={Object.values(HandType).map(value => ({
                  id: value,
                  name: HandTypeNames[value],
                }))}
              />

              <ReferenceField
                label="스타일"
                source="pitchStyle"
                reference="categories"
                link={false}>
                <TextField source="name" />
              </ReferenceField>

              <TextField label="지원자구분" source="playerType" />

              <DateField label="지원일" source="createdAt" showTime />
              <DownloadButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export const GameCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="template"
          reference="templates"
          fullWidth
          filter={{type: TemplateType.GAME}}
          sort={{field: 'tag', order: 'ASC'}}>
          <SelectInput
            optionText="tag"
            label="템플릿"
            validate={[required()]}
          />
        </ReferenceInput>
        <BooleanInput label="촬영동의서필요" source="isNeedAgreement" />
        <TextInput
          label="제목"
          source="title"
          fullWidth
          placeholder="[유튜브] 서울 오란다볼링파크 팀 대항전"
          validate={[required()]}
        />
        <TextInput
          label="서브제목"
          source="subtitle"
          placeholder="스트로커 - 크랭커 - 덤리스 - 투핸드 팀당 3명, 서든데스 경기"
          fullWidth
          validate={[required()]}
        />
        <DateTimeInput label="경기일시" source="gameAt" />
        <DateInput
          label="모집시작일"
          source="acceptanceDateRange.from"
          validate={[required()]}
        />
        <DateInput
          label="모집종료일"
          source="acceptanceDateRange.to"
          validate={[required()]}
        />
        <ReferenceInput source="center" reference="centers" fullWidth>
          <AutocompleteInput optionText="name" label="센터" />
        </ReferenceInput>
        <SelectInput
          label="타입"
          source="type"
          choices={Object.values(GameType).map(value => ({
            id: value,
            name: GameTypeNames[value],
          }))}
          validate={[required()]}
        />
        <TextInput
          label="상금"
          source="prizeText"
          placeholder="총 상금 1000만원"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="가격"
          source="priceText"
          placeholder="참가비 50,000원"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="에버리지 제한"
          source="averageText"
          placeholder="AVG 제한없음"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="경기형식"
          source="matchFormat"
          placeholder="팀 서바이벌"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="복장"
          source="wear"
          placeholder="단체복"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="정비패턴"
          source="pattern"
          placeholder="볼닥패턴"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          label="성별"
          source="genderTarget"
          choices={Object.values(GameGenderTarget).map(value => ({
            id: value,
            name: GameGenderTargetNames[value],
          }))}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
