import {Datagrid, Edit, List, TextField, useRecordContext} from 'react-admin';

import {LazyLog, ScrollFollow} from 'react-lazylog';
//
// List
export const LogList = () => (
  <List exporter={false} pagination={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="로그" source="filename" />
    </Datagrid>
  </List>
);

//
// Edit
const LogTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `"${record.filename}"` : ''}</span>;
};

const BasicSearchLogViewer = () => {
  const record = useRecordContext();

  if (record.data == null) return null;

  return (
    <ScrollFollow
      startFollowing
      render={({onScroll, follow, startFollowing, stopFollowing}) => (
        <LazyLog
          enableSearch
          height={800}
          text={record.data}
          selectableLines
          follow={follow}
        />
      )}
    />
  );
};

export const LogEdit = () => (
  <Edit title={<LogTitle />}>
    <BasicSearchLogViewer />
  </Edit>
);
