import {ID, PaymentMethod} from '.';

export enum MembershipState {
  IDLE = 'idle',
  PAID = 'paid',
  CONSUMED = 'consumed',
  CANCELED = 'canceled', // 소비는 하였으나, 다음 결제 안함,
  REFUNDED = 'refunded', // 환불
}
export const MembershipStateNames = {
  idle: '대기',
  paid: '결제',
  consumed: '소비',
  canceled: '소비후 중지',
  refunded: '환불',
};

export enum MembershipType {
  FREE = 'free', //한달간 무료
  MONTHLY = 'monthly',
  // YEARLY = 'yearly',
}
export const MembershipTypeNames = {
  free: '무료',
  monthly: '월간',
  // yearly: '연간',
};

export enum MembershipTarget {
  // TRAINER = 'trainer',
  // DRILLER = 'driller',
  PARTNER = 'partner',
}
export const MembershipTargetNames = {
  // trainer: '트레이너',
  // driller: '프로샵',
  partner: '파트너',
};

export interface IMembership {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  expireAt?: Date;

  type: MembershipType;
  target: MembershipTarget;
  state: MembershipState;

  user: ID;

  payment?: ID;

  paymentEntity?: {
    id: ID;
    number: number;
    tid?: string;
    amount: number;
    refundAmount: number;
    method: PaymentMethod;
  };
}
