import * as React from 'react';
import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  List,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {
  CategoryType,
  CategoryTypeNames,
  ICategory,
  RoleType,
} from '../common/types';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';

const BulkActionButtons: FC<any> = () => (
  <>
    <MoveUpButton />
    <MoveDownButton />
    <BulkDeleteButton />
  </>
);

//
// List
const CategoryFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(CategoryType).map(value => ({
      id: value,
      name: CategoryTypeNames[value],
    }))}
    alwaysOn
  />,
];

export const CategoryList = () => {
  const {permissions} = usePermissions();
  return (
    <List
      filters={CategoryFilter}
      sort={{field: 'position', order: 'DESC'}}
      exporter={false}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          permissions === RoleType.ADMIN ? <BulkActionButtons /> : false
        }>
        <SelectField
          label="타입"
          source="type"
          choices={Object.values(CategoryType).map(value => ({
            id: value,
            name: CategoryTypeNames[value],
          }))}
        />
        <TextField label="이름" source="name" />

        <BooleanField label="활성" source="enabled" />
        <TextField label="위치" source="position" />
      </Datagrid>
    </List>
  );
};

//
// Edit
const CategoryTitle = () => {
  const record = useRecordContext<ICategory>();
  return <span>카테고리 {record ? `"${record.name}"` : ''}</span>;
};

const CategoryEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const CategoryEdit = () => (
  <Edit title={<CategoryTitle />}>
    <SimpleForm toolbar={<CategoryEditToolbar />}>
      <TextInput label="이름" source="name" validate={[required()]} fullWidth />

      <BooleanInput
        label="활성"
        source="enabled"
        helperText="활성 후에는 비활성이 불가능합니다!"
      />

      <TextInput
        label="태그"
        source="tag"
        fullWidth
        helperText="태그는 front에서 구분자로 사용합니다. 한번 설정 후에는 변경 불가능합니다."
      />
    </SimpleForm>
  </Edit>
);

//
// Create
export const CategoryCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <SelectInput
          label="타입"
          source="type"
          choices={Object.values(CategoryType).map(value => ({
            id: value,
            name: CategoryTypeNames[value],
          }))}
          validate={[required()]}
        />

        <TextInput
          label="이름"
          source="name"
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
