import * as React from 'react';
import {FC} from 'react';
import {DateField, useRecordContext} from 'react-admin';
import dayjs from 'dayjs';
const ExprieDateField: FC<any> = ({label, source}) => {
  const {membershipExpireAt} = useRecordContext();

  let color = '#000000';

  if (dayjs(membershipExpireAt).isBefore(dayjs())) {
    color = '#C0C0C0';
  } else if (dayjs(membershipExpireAt).isBefore(dayjs().add(7, 'day'))) {
    color = '#FF0000';
  }
  return (
    <DateField
      label={label}
      source={source}
      showTime={false}
      sx={{
        color,
      }}
    />
  );
};

export default ExprieDateField;
