import {useEffect, useState} from 'react';
import {Api} from '../utils/api';
import {GalleryReviewState} from '../common/types';

const useBadge = () => {
  const [loading, setLoading] = useState(false);
  const [uncheckReportCount, setUncheckReportCount] = useState<number>(0);
  const [inspectingTrainerCount, setInspectingTrainerCount] =
    useState<number>(0);
  const [inspectingDrillerCount, setInspectingDrillerCount] =
    useState<number>(0);
  const [pendingGalleryCount, setPendingGalleryCount] = useState<number>(0);

  useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = async () => {
    setLoading(true);
    try {
      const {data: reports} = await Api.get<any, any>(
        `/reports?limit=0&filter=${encodeURIComponent(
          JSON.stringify({
            check: false,
          }),
        )}`,
      );
      setUncheckReportCount(reports.length);

      const {data: trainers} = await Api.get<any, any>(
        `/trainers?limit=0&filter=${encodeURIComponent(
          JSON.stringify({
            inspecting: true,
          }),
        )}`,
      );
      setInspectingTrainerCount(trainers.length);

      const {data: drillers} = await Api.get<any, any>(
        `/drillers?limit=0&filter=${encodeURIComponent(
          JSON.stringify({
            inspecting: true,
          }),
        )}`,
      );
      setInspectingDrillerCount(drillers.length);

      const {data: galleries} = await Api.get<any, any>(
        `/galleries?limit=0&filter=${encodeURIComponent(
          JSON.stringify({
            reviewState: GalleryReviewState.PENDING,
          }),
        )}`,
      );
      setPendingGalleryCount(galleries.length);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    uncheckReportCount,
    inspectingTrainerCount,
    inspectingDrillerCount,
    pendingGalleryCount,
  };
};

export default useBadge;
