import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  ImageInput,
  Labeled,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import {CenterEventType, CenterEventTypeNames, RoleType} from '../common/types';
import PreviewImage from '../components/PreviewImage';

const CenterEventFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(CenterEventType).map(value => ({
      id: value,
      name: CenterEventTypeNames[value],
    }))}
    alwaysOn
  />,
  <ReferenceInput source="center" reference="centers" fullWidth alwaysOn>
    <AutocompleteInput optionText="name" label="센터" />
  </ReferenceInput>,
  <ReferenceInput
    source="author"
    reference="users"
    filter={{role: RoleType.USER}}
    fullWidth
    alwaysOn>
    <AutocompleteInput optionText="name" label="작성회원" />
  </ReferenceInput>,
];

export const CenterEventList = () => (
  <List
    filters={CenterEventFilter}
    sort={{field: 'createdAt', order: 'DESC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField label="센터" source="center" reference="centers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="작성회원" source="author" reference="users">
        <TextField source="name" />
      </ReferenceField>

      <SelectField
        label="타입"
        source="type"
        choices={Object.values(CenterEventType).map(value => ({
          id: value,
          name: CenterEventTypeNames[value],
        }))}
      />

      <TextField label="제목" source="title" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="생성일" source="createdAt" showTime={true} />
      <DateField label="갱신일" source="updatedAt" showTime={true} />
    </Datagrid>
  </List>
);

//
// Edit
const CenterEventTitle = () => {
  return <span>클럽</span>;
};

const CenterEventEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const CenterEventEdit = () => {
  return (
    <Edit title={<CenterEventTitle />}>
      <SimpleForm toolbar={<CenterEventEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="updatedAt" label="갱신일" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="센터" source="center" reference="centers">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <ReferenceField label="작성회원" source="author" reference="users">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        <BooleanInput label="활성" source="enabled" />

        <SelectInput
          label="타입"
          source="type"
          choices={Object.values(CenterEventType).map(value => ({
            id: value,
            name: CenterEventTypeNames[value],
          }))}
          validate={[required()]}
        />

        <TextInput label="제목" source="title" fullWidth />

        <TextInput
          label="내용"
          source="content"
          multiline
          fullWidth
          validate={[required()]}
        />

        <ImageInput source="cover" label="커버사진" accept="image/*">
          <PreviewImage source="src" />
        </ImageInput>

        <ImageInput
          source="contentPhotos"
          label="사진"
          accept="image/*"
          multiple>
          <PreviewImage source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
