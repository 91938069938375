import {ID, PartnerType, RoleType} from '.';

export enum ChatroomUserState {
  READY = 'ready', // 들어온적이 없음
  ENTERED = 'entered', // 들어와 있음
  EXITED = 'exited', // 들어왔다 나감
  TERMINATED = 'terminated', // 삭제해 버림
}

export const ChatroomUserStateNames = {
  ready: '들어온적없음',
  entered: '들어옴',
  exited: '나감',
  terminated: '삭제',
};

export enum ChatroomType {
  PARTNER = 'partner',
  CLUB = 'club',
}

export const ChatroomTypeNames = {
  partner: '파트너',
  club: '센터클럽',
};

export interface IChatroomUserInfo {
  state: ChatroomUserState;
  user: ID;
  unreadCount: number;
  enteredAt?: Date;
  exitedAt?: Date;
  terminatedAt?: Date;
  heartbeatAt?: Date;
  averageResponseMinutes?: number;
}

export interface IChatroom {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  type: ChatroomType;

  center?: ID;

  users: ID[];

  userInfos: IChatroomUserInfo[];

  tailMessage: string;
  tailMessageAt: Date;

  partnerType?: PartnerType;

  usersEntities?: {
    id: ID;
    name: string;
    photo: string;
    role: RoleType;

    paidTrainers: ID[];
    closedTrainers: ID[];
    reviewTrainers: ID[];
  }[];
}
