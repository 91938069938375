import * as React from 'react';
import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  Filter,
  Labeled,
  List,
  ListProps,
  ReferenceField,
  SaveButton,
  SelectField,
  SimpleForm,
  TextField,
  Toolbar,
  usePermissions,
} from 'react-admin';
import {ReportResource, ReportResourceNames, RoleType} from '../common/types';

//
// List
const ReportFilter = (props: any) => {
  return <Filter {...props}></Filter>;
};

export const ReportList: FC<ListProps> = props => {
  return (
    <List
      filters={<ReportFilter />}
      {...props}
      sort={{field: 'createdAt', order: 'DESC'}}
      exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <ReferenceField label="회원" source="user" reference="users">
          <TextField source="name" />
        </ReferenceField>

        <SelectField
          label="리소스"
          source="resource"
          choices={Object.values(ReportResource).map(value => ({
            id: value,
            name: ReportResourceNames[value],
          }))}
        />
        <TextField label="내용" source="text" />

        <DateField label="작성일" source="createdAt" showTime={true} />

        <BooleanField label="체크" source="check" />
      </Datagrid>
    </List>
  );
};

//
// Edit
const ReportTitle: FC<FieldProps> = ({record}) => {
  return <span>신고/센터수정권한요청</span>;
};

const ReportEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const ReportEdit: FC<EditProps> = props => (
  <Edit title={<ReportTitle />} {...props}>
    <SimpleForm toolbar={<ReportEditToolbar />}>
      <Labeled sx={{marginBottom: 4}}>
        <DateField source="createdAt" label="신고일" showTime={true} />
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <ReferenceField label="회원" source="user" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <SelectField
          label="대상"
          source="resource"
          choices={Object.values(ReportResource).map(value => ({
            id: value,
            name: ReportResourceNames[value],
          }))}
        />
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <TextField label="내용" source="text" fullWidth />
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <ReferenceField label="대상 센터" source="ref" reference="centers">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <ReferenceField label="대상 회원" source="ref" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>

      <Labeled sx={{marginBottom: 4}}>
        <ReferenceField label="대상 파트너컨텐츠" source="ref" reference="galleries">
          <TextField source="text" />
        </ReferenceField>
      </Labeled>

      <BooleanInput label="체크" source="check" fullWidth />
    </SimpleForm>
  </Edit>
);
