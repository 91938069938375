import {
  BooleanField,
  Datagrid,
  DateField,
  Edit,
  Labeled,
  List,
  NumberField,
  ReferenceField,
  SaveButton,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import {IMallOrder, MallOrderState, MallOrderStateNames} from '../common/types';
import MallOrderCancelStateButton from './mall-orders/MallOrderCancelStateButton';
import MallOrderRefundStateButton from './mall-orders/MallOrderRefundStateButton';
import MallOrderStateButton from './mall-orders/MallOrderStateButton';

export const MallOrderList = () => {
  return (
    <List sort={{field: 'createdAt', order: 'DESC'}} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="주문번호" source="orderNumber" />

        <ReferenceField label="회원" source="user" reference="users">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="상품"
          source="mallProduct"
          reference="mall-products">
          <TextField source="title" />
        </ReferenceField>

        <SelectField
          label="상태"
          source="state"
          choices={Object.values(MallOrderState).map(value => ({
            id: value,
            name: MallOrderStateNames[value],
          }))}
        />

        <BooleanField label="취소/반품요청" source="inRequestCancel" />
        <NumberField label="결제금액" source="totalAmount" />

        <DateField label="결제일" source="paidAt" showTime={true} />
      </Datagrid>
    </List>
  );
};

const MallOrderTitle = () => {
  const record = useRecordContext<IMallOrder>();
  return <span>쇼핑몰 주문 {record ? `"${record.productTitle}"` : ''}</span>;
};

const MallOrderEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
      <MallOrderStateButton />
      <MallOrderCancelStateButton />
      <MallOrderRefundStateButton />
    </Toolbar>
  );
};

export const MallOrderEdit = () => {
  return (
    <Edit title={<MallOrderTitle />}>
      <SimpleForm toolbar={<MallOrderEditToolbar />}>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="주문번호" source="orderNumber" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="생성일" showTime={true} />
        </Labeled>

        <ReferenceField label="회원" source="user" reference="users">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="상품"
          source="mallProduct"
          reference="mall-products">
          <TextField source="title" />
        </ReferenceField>

        <Labeled sx={{marginBottom: 4}}>
          <SelectField
            label="상태"
            source="state"
            choices={Object.values(MallOrderState).map(value => ({
              id: value,
              name: MallOrderStateNames[value],
            }))}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <BooleanField label="취소/반품요청" source="inRequestCancel" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            source="requestCancelAt"
            label="취소/반품요청일"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="취소/반품요청 이유" source="cancelReason" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField label="결제일" source="paidAt" showTime={true} />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            label="상품준비중 시간"
            source="packingAt"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField
            label="배송준비중 시간"
            source="preparingAt"
            showTime={true}
          />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <DateField label="배송중 시간" source="shippingAt" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField label="배송완료일" source="arrivedAt" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField label="구매확정일" source="closedAt" showTime={true} />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <DateField label="취소일" source="canceledAt" showTime={true} />
        </Labeled>

        <TextInput label="송장번호" source="trackId" multiline fullWidth />

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="받는사람" source="addressReceiver" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="연락처" source="addressPhone" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="우편번호" source="zipCode" />
        </Labeled>
        <Labeled sx={{marginBottom: 4}}>
          <TextField label="주소" source="address" />
        </Labeled>

        <Labeled sx={{marginBottom: 4}}>
          <TextField label="배송메시지" source="shippingMemo" />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
