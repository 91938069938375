import {useEffect, useState} from 'react';
import {ID, IParticipation} from '../common/types';
import {Api} from '../utils/api';

interface UseParticipationProps {
  id: ID;
}

const useParticipation = (props: UseParticipationProps) => {
  const {id} = props;
  const [loading, setLoading] = useState(false);
  const [particiation, setParticipation] = useState<IParticipation>();

  useEffect(() => {
    _fetchParticipation(id);
  }, []);

  const _fetchParticipation = async (id: ID) => {
    setLoading(true);
    try {
      const data = await Api.get<any, any>(`/participations/${id}`);
      setParticipation(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    particiation,
  };
};

export default useParticipation;
