import {ArrowDownward} from '@mui/icons-material';
import {useState} from 'react';
import {Button, useListContext, useNotify, useRefresh} from 'react-admin';
import {Api, ApiError} from '../utils/api';

const MoveDownButton = () => {
  const {selectedIds, resource} = useListContext();

  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const allowPositions =
    ['categories', 'faqs', 'banners', 'popup-notices'].includes(resource) &&
    (((window.location.href.includes('sort=position') ||
      window.location.href.includes('sort=recommend')) &&
      window.location.href.includes('order=DESC')) ||
      !window.location.href.includes('sort='));

  const allowRecommendPositions =
    ['galleries'].includes(resource) &&
    ((window.location.href.includes('sort=recommendPosition') &&
      window.location.href.includes('order=DESC')) ||
      !window.location.href.includes('sort='));

  const allow = allowPositions || allowRecommendPositions;

  return (
    <Button
      label="아래로"
      disabled={!allow || loading}
      onClick={async () => {
        try {
          setLoading(true);

          await Api.put(`/${resource}/move-down`, {
            ids: selectedIds,
          });

          refresh();
          notify('변경하였습니다.');
        } catch (err) {
          const e = err as ApiError;
          notify(e.message, {type: 'warning'});
        } finally {
          setLoading(false);
        }
      }}>
      <ArrowDownward />
    </Button>
  );
};

export default MoveDownButton;
