import * as React from 'react';
import {FC} from 'react';
import {ImageField, useRecordContext} from 'react-admin';

const ThumbnailField: FC<any> = ({source}) => {
  const record = useRecordContext();

  return (
    <ImageField
      record={record}
      source={source}
      sx={{
        '& .RaImageField-image': {
          height: 50,
          width: 50,
          objectFit: 'cover',
        },
      }}
    />
  );
};

export default ThumbnailField;
