import {
  Create,
  Datagrid,
  DateField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

//
// List
export const ApplinkList = () => (
  <List sort={{field: 'createdAt', order: 'DESC'}} exporter={false}>
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <TextField label="제목" source="title" />
      <TextField label="캠페인" source="campaign" />
      <TextField label="채널" source="channel" />
      <TextField label="딥링크" source="deeplink" />
      <TextField label="디퍼드딥링크" source="link" />
      <DateField label="생성날짜" source="createdAt" showTime={true} />
    </Datagrid>
  </List>
);

export const ApplinkCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="제목"
          source="title"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="채널"
          source="channel"
          placeholder="sms, facebook..."
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="캠페인"
          source="campaign"
          placeholder="알림, 런칭기념..."
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="딥링크"
          source="deeplink"
          placeholder="home, "
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
