import {ID} from '.';

export enum ChatType {
  MESSAGE = 'message',
  PHOTO = 'photo',
  SYSTEM = 'system',
}

export const ChatTypeNames = {
  message: '메시지',
  photo: '사진',
  system: '시스템메시지',
};

export interface IChat {
  id: string; // not mongodb id

  type: ChatType;

  timestamp: Date;

  sender: ID;

  message: string;

  reqeustResponseDate?: Date;
  responseMinutes?: number;
}
