import * as React from 'react';
import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {CategoryType, IFaq, RoleType} from '../common/types';
import MoveDownButton from '../components/MoveDownButton';
import MoveUpButton from '../components/MoveUpButton';

const BulkActionButtons: React.FC<any> = () => (
  <>
    <MoveUpButton />
    <MoveDownButton />
    <BulkDeleteButton />
  </>
);

const FaqFilter = [
  <TextInput label="제목 내용" source="q" alwaysOn />,

  <ReferenceInput
    source="category"
    reference="categories"
    fullWidth
    alwaysOn
    filter={{type: CategoryType.FAQ}}
    sort={{field: 'position', order: 'DESC'}}>
    <SelectInput optionText="name" label="카테고리" />
  </ReferenceInput>,
];

export const FaqList = () => {
  const {permissions} = usePermissions();

  return (
    <List
      filters={FaqFilter}
      sort={{field: 'position', order: 'DESC'}}
      exporter={false}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={
          permissions === RoleType.ADMIN ? <BulkActionButtons /> : false
        }>
        <ReferenceField
          label="카테고리"
          source="category"
          reference="categories">
          <TextField source="name" />
        </ReferenceField>

        <TextField label="제목" source="title" />
        <BooleanField label="활성" source="enabled" />
        <TextField label="위치" source="position" />
      </Datagrid>
    </List>
  );
};

const FaqTitle = () => {
  const record = useRecordContext<IFaq>();
  return <span>Faq {record ? `"${record.title}"` : ''}</span>;
};

const FaqEditToolbar = () => {
  const {permissions} = usePermissions();
  return (
    <Toolbar>
      {permissions === RoleType.ADMIN && (
        <>
          <SaveButton />
          <DeleteButton />
        </>
      )}
    </Toolbar>
  );
};

export const FaqEdit = () => {
  return (
    <Edit title={<FaqTitle />}>
      <SimpleForm toolbar={<FaqEditToolbar />}>
        <ReferenceInput
          source="category"
          reference="categories"
          fullWidth
          alwaysOn
          filter={{type: CategoryType.FAQ}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput optionText="name" label="카테고리" />
        </ReferenceInput>
        <TextInput
          label="제목"
          source="title"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />
        <BooleanInput label="활성" source="enabled" />
      </SimpleForm>
    </Edit>
  );
};

export const FaqCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="category"
          reference="categories"
          fullWidth
          alwaysOn
          filter={{type: CategoryType.FAQ}}
          sort={{field: 'position', order: 'DESC'}}>
          <SelectInput optionText="name" label="카테고리" />
        </ReferenceInput>
        <TextInput
          label="제목"
          source="title"
          fullWidth
          validate={[required()]}
        />

        <TextInput
          label="내용"
          source="text"
          multiline
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
