import {FC} from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FieldProps,
  Labeled,
  List,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

export const NoticeList = () => (
  <List sort={{field: 'createdAt', order: 'DESC'}} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="제목" source="title" />
      <BooleanField label="활성" source="enabled" />
      <DateField label="등록일" source="createdAt" />
      <NumberField label="조회수" source="viewCount" />
    </Datagrid>
  </List>
);

//
// Edit
const NoticeTitle: FC<FieldProps> = ({record}) => {
  return <span>공지사항 {record ? `"${record.title}"` : ''}</span>;
};

export const NoticeEdit = () => {
  return (
    <Edit title={<NoticeTitle />}>
      <SimpleForm>
        <Labeled sx={{marginBottom: 4}}>
          <DateField source="createdAt" label="등록일" showTime={true} />
        </Labeled>
        <BooleanInput label="활성" source="enabled" />

        <TextInput
          label="제목"
          source="title"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="내용"
          source="content"
          multiline
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

//
// Create
export const NoticeCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="제목"
          source="title"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="내용"
          source="content"
          multiline
          fullWidth
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
