import {FC} from 'react';
import {
  ArrayInput,
  Datagrid,
  Edit,
  FieldProps,
  FormDataConsumer,
  List,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {RoleType} from '../common/types';
import {
  ITemplate,
  TemplateTag,
  TemplateTagNames,
  TemplateType,
  TemplateTypeNames,
} from '../common/types/Template.type';
import RTEditor from '../components/RTEditor';

//
// List
const TemplateFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(TemplateType).map(value => ({
      id: value,
      name: TemplateTypeNames[value],
    }))}
    alwaysOn
  />,
];
export const TemplateList = () => (
  <List
    filters={TemplateFilter}
    sort={{field: 'tag', order: 'ASC'}}
    exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <SelectField
        label="타입"
        source="type"
        choices={Object.values(TemplateType).map(value => ({
          id: value,
          name: TemplateTypeNames[value],
        }))}
      />
      <TextField label="코드" source="code" />

      <SelectField
        label="시스템태그"
        source="tag"
        choices={Object.values(TemplateTag).map(value => ({
          id: value,
          name: TemplateTagNames[value],
        }))}
      />
    </Datagrid>
  </List>
);

//
// Edit
const TemplateTitle: FC<FieldProps> = () => {
  const record = useRecordContext<ITemplate>();
  return (
    <span>
      {record ? `"${TemplateTagNames[record.tag as TemplateTag]}"` : ''}
    </span>
  );
};

const TemplateEditToolbar = () => {
  const {permissions} = usePermissions();
  return <Toolbar>{permissions === RoleType.ADMIN && <SaveButton />}</Toolbar>;
};

export const TemplateEdit = () => (
  <Edit title={<TemplateTitle />}>
    <SimpleForm toolbar={<TemplateEditToolbar />}>
      <FormDataConsumer>
        {({formData}) => {
          if (formData.type === TemplateType.EMAIL) {
            return (
              <>
                <TextInput
                  label="제목"
                  source="title"
                  validate={[required()]}
                  fullWidth
                />
                <RTEditor source="html" />
              </>
            );
          } else if (formData.type === TemplateType.GAME) {
            return (
              <>
                <ArrayInput label="내용" source="sections">
                  <SimpleFormIterator>
                    <TextInput
                      label="제목"
                      source="title"
                      validate={[required()]}
                      fullWidth
                    />
                    <TextInput
                      label="내용"
                      source="body"
                      validate={[required()]}
                      multiline
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            );
          } else if (formData.type === TemplateType.ALIMTALK) {
            return (
              <>
                <TextInput
                  label="코드"
                  source="code"
                  validate={[required()]}
                  fullWidth
                />
                <TextInput
                  label="내용"
                  source="text"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
                <TextInput
                  label="버튼이름"
                  source="buttons"
                  fullWidth
                  helperText="콤마(,)로 구분"
                />
                <TextInput
                  label="링크"
                  source="link"
                  fullWidth
                  helperText="콤마(,)로 구분"
                />
              </>
            );
          } else {
            return (
              <>
                <TextInput
                  label="제목"
                  source="title"
                  validate={[required()]}
                  fullWidth
                />
                <TextInput
                  label="내용"
                  source="text"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
              </>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
