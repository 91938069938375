import React, {FC} from 'react';
import {useRecordContext} from 'react-admin';

export const TextArrayField: FC<any> = ({source}) => {
  const record = useRecordContext();

  return (
    <>
      {record[source]?.map((value: any, index: number) => (
        <li key={value + index}>{value}</li>
      ))}
    </>
  );
};
