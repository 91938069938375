import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import {
  IMembership,
  IUser,
  MembershipState,
  MembershipStateNames,
  MembershipTarget,
  MembershipTargetNames,
  MembershipType,
  MembershipTypeNames,
  PaymentMethodName,
  RoleType,
} from '../common/types';

import {Card, CardContent, Grid, Typography} from '@mui/material';

// import Thumbnail from "../components/Thumbnail";

import ListHelper from '../components/ListHelper';

const MembershipFilter = [
  <SelectInput
    label="타입"
    source="type"
    choices={Object.values(MembershipType).map(value => ({
      id: value,
      name: MembershipTypeNames[value],
    }))}
    alwaysOn
  />,
  <SelectInput
    label="대상"
    source="target"
    choices={Object.values(MembershipTarget).map(value => ({
      id: value,
      name: MembershipTargetNames[value],
    }))}
    alwaysOn
  />,

  <SelectInput
    label="상태"
    source="state"
    choices={Object.values(MembershipState).map(value => ({
      id: value,
      name: MembershipStateNames[value],
    }))}
    alwaysOn
  />,

  <ReferenceInput
    source="user"
    reference="users"
    filter={{role: RoleType.PARTNER}}
    fullWidth
    alwaysOn>
    <AutocompleteInput
      optionText={(choice: IUser) => `${choice.name}(${choice.phone})`}
      label="파트너회원"
    />
  </ReferenceInput>,
];

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
  );
};

export const MembershipList = () => {
  return (
    <>
      <ListHelper
        contents={[
          {
            title: '상태',
            text: `결제: 결제완료
소비: 사용함`,
          },
        ]}
      />

      <List
        actions={<ListActions />}
        filters={MembershipFilter}
        sort={{field: 'createdAt', order: 'DESC'}}
        exporter={false}>
        <Datagrid
          rowClick="expand"
          expand={<InvoiceShow />}
          bulkActionButtons={false}>
          <SelectField
            label="타입"
            source="type"
            choices={Object.values(MembershipType).map(value => ({
              id: value,
              name: MembershipTypeNames[value],
            }))}
          />

          <SelectField
            label="대상"
            source="target"
            choices={Object.values(MembershipTarget).map(value => ({
              id: value,
              name: MembershipTargetNames[value],
            }))}
          />

          <SelectField
            label="상태"
            source="state"
            choices={Object.values(MembershipState).map(value => ({
              id: value,
              name: MembershipStateNames[value],
            }))}
          />

          <ReferenceField label="파트너 회원" source="user" reference="users">
            <TextField source="name" />
          </ReferenceField>

          <NumberField label="결제금액" source="paymentEntity.amount" />

          {/* <NumberField label="환불금액" source="paymentEntity.refundAmount" /> */}

          <DateField label="결제일" source="createdAt" showTime={true} />
          <DateField label="만료일" source="expireAt" showTime={true} />
        </Datagrid>
      </List>
    </>
  );
};

const InvoiceShow = () => {
  const record = useRecordContext<IMembership>();
  if (!record) return null;
  return (
    <Card sx={{width: 600, margin: 'auto'}}>
      <CardContent>
      <Grid container>
          <Typography>결제수단</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.method
              ? PaymentMethodName[record.paymentEntity?.method]
              : ''}
          </Typography>
        </Grid>

        <Grid container>
          <Typography>TID</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.tid}
          </Typography>
        </Grid>

        <Grid container>
          <Typography>주문번호</Typography>
          <Typography sx={{marginLeft: 8}}>
            {record.paymentEntity?.number}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};
