import {GenderType, ID, Photo} from '.';
import {PaymentMethod, PaymentSettlementState} from './Payment.type';

export enum CertificateState {
  IDLE = 'idle',
  APPLIED = 'applied',
  CONFIRMED = 'confirmed',
  CLOSED = 'closed',
  CANCELED = 'canceled',
}
export const CertificateStateNames = {
  idle: '대기',
  applied: '신청',
  confirmed: '확정',
  closed: '종료',
  canceled: '취소',
};

export enum CertificateCancelType {
  USER = 'user',
  TRAINER = 'trainer',
  NOT_CONFIRMED = 'not-confirmed',
}

export const CertificateCancelTypeNames = {
  user: '회원취소',
  trainer: '트레이너취소',
  'not-confirmed': '미확정자동취소',
};

export enum CertificateClosedState {
  CLOSED = 'closed',
  NO_SHOW = 'no-show',
}

export const CertificateClosedStateNames = {
  closed: '출석',
  'no-show': '노쇼',
};

export enum CertificateType {
  TRIAL = 'trial',
  DRILLER = 'driller',
  LESSON = 'lesson',
  SINGLE_LESSON = 'single-lesson',
  GROUP_LESSON = 'group-lesson',
}
export const CertificateTypeNames = {
  trial: '1회체험레슨',
  driller: '1회체험지공',
  lesson: '그룹레슨(구)',
  'single-lesson': '일반레슨',
  'group-lesson': '그룹레슨',
};

export interface CertificateCheckResult {
  type: CertificateType;
  resourceId: ID; // trainer 또는 lesson ID
  price: number; // 표시되는 가격
  amount: number; // 실제 결제되어야 하는 가격
  discountAmount: number; // 할인 금액
  title: string;
  settlementRate: number;
  trainerId: ID;
  lessonId?: ID;
  drillerId?: ID;
  optionText?: string;
  headCount?: number;
  totalRound?: number;
  payParams: {
    MID?: string;
    Moid?: string;
    MallUserID: string; // innopay에만 필요함
    MerchantKey: string; // innopay에만 필요함
    OfferingPeriod: string; // innopay에만 필요함
    kcpMoid: string;
    kcpUserCode: string;
    kcpId: string;
  };
}

export interface ICertificate {
  id: ID;

  createdAt: Date;
  updatedAt: Date;

  confirmedAt?: Date;
  canceledAt?: Date;
  closedAt?: Date;
  type: CertificateType;
  state: CertificateState;

  optionText?: string; // 지공체험에서 옵션이 있는 경우

  cancelType?: CertificateCancelType;
  cancelText?: string; // 2일전 회원취소, 1일전 회원취소 일 경우만 작성
  closedState?: CertificateClosedState;

  user: ID;

  center: ID;
  trainer: ID; // trial, lesson(1회체험지공 이더라도 반드시 있음)
  lesson?: ID; //  lesson
  driller?: ID; // driller

  review?: ID;
  payment: ID;

  classDate?: Date; // 수업일시
  closedDate?: Date; // 종료일

  //
  // SINGLE_LESSON, GROUP_LESSON
  parent?: ID; // 2회차 부터 parent 가 1회차를 가리킴
  round?: number; // 회차(1부터 시작)
  totalRound?: number; // 총 회차
  headCount?: number; // 그룹인 경우 인원수

  paymentEntity?: {
    id: ID;
    number: string;
    amount: number;
    refundAmount: number;
    discountAmount: number;
    settlementState: PaymentSettlementState;
    settlementExpectAmount: number;
    settlementAmount: number;
    tid: string;
    method: PaymentMethod;
  };

  centerEntity?: {
    id: ID;
    name: string;
    address: string;
    addressDetail?: string;
  };

  trainerEntity?: {
    id: ID;
    name: string;
    photos: Photo[];
  };

  drillerEntity?: {
    id: ID;
    name: string;
    photos: Photo[];
  };

  userEntity?: {
    id: ID;
    photo?: Photo;
    name: string;
    gender: GenderType;
  };

  virtual?: {
    cover?: string;
  };
}
