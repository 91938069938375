import dayjs from 'dayjs';
import {ILesson, WeekdayNames} from '../common/types';

export const toPrice = (value?: number | null): string => {
  if (!value) return '-';
  return Math.round(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getFileName = (url: string): string => {
  if (url) {
    return url.split('/').pop() || '';
  }
  return '';
};

export const toLessonDateString = (lesson?: ILesson) => {
  let ret = '';

  if (lesson === undefined) return ret;

  const weekdays = lesson.weekdays
    .map(d => `${WeekdayNames[d]}요일`)
    .join(', ');

  const fromHour = dayjs(
    dayjs().format('YYYY-MM-DD') + ' ' + lesson.hourRage?.from,
  ).toDate();
  const toHour = dayjs(
    dayjs().format('YYYY-MM-DD') + ' ' + lesson.hourRage?.to,
  ).toDate();

  let fromHourStr = dayjs(fromHour).format('HH시mm분');
  if (dayjs(fromHour).minute() === 0) {
    fromHourStr = dayjs(fromHour).format('HH시');
  }

  let toHourStr = dayjs(toHour).format('HH시mm분');
  if (dayjs(toHour).minute() === 0) {
    toHourStr = dayjs(toHour).format('HH시');
  }

  ret = `매주 ${weekdays} ${fromHourStr}~${toHourStr}`;
  return ret;
};
