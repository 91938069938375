import BackupIcon from '@mui/icons-material/DownloadForOffline';
import {CircularProgress} from '@mui/material';
import React from 'react';
import {
  Button,
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  useRecordContext,
} from 'react-admin';
import {Api} from '../utils/api';
import {fileDownload} from '../utils/fileDownload';

export const DownloadButton = (props: any) => {
  const record = useRecordContext(props);
  const [loading, setLoading] = React.useState(false);
  if (!record) return null;
  return (
    <Button
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          const blob = await Api.get(`/backups/download?file=${record.id}`, {
            responseType: 'blob',
          });
          const filename = '' + record.id;
          fileDownload(blob, filename);
        } catch (err) {
          // notify(err, "warning");
        } finally {
          setLoading(false);
        }
      }}
      label="다운로드">
      {loading ? <CircularProgress size={18} thickness={2} /> : <BackupIcon />}
    </Button>
  );
};

//
// List
export const BackupList = () => (
  <List exporter={false} pagination={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="백업파일" source="filename" />
      <DateField label="생성일" source="birthtime" showTime={true} />
      <NumberField label="크기(K)" source="size" />
      <DownloadButton />
    </Datagrid>
  </List>
);
