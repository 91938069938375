import Box from '@mui/material/Box';
import * as React from 'react';
import {FC} from 'react';

type Props = {
  title?: string;
};

const Section: FC<Props> = ({title}) => {
  return (
    <Box sx={{margin: '2em 0 1em 0'}}>
      <Box sx={{fontWeight: 700}}>{title}</Box>
      <Box sx={{borderTop: '1px dashed'}} />
    </Box>
  );
};

export default Section;
